<swe-card [header]="header.length>0?header:languageService.getItem(1234)" [(open)]="open" [color]="cardcolor" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="load()" (openChange)="load()" [tabs]="tabs" (activeChange)="loadByTab($event)" *ngIf="(salaryaccess>0)||(invoiceaccess>0)">
  <div class="mb-2" swe-uppercontent>
    <swe-datepager [type]="'booking'" (dateChange)="load()" [disabled]="isloading" [hideTime]="true" *ngIf="isoverview"></swe-datepager>
    <swe-filteritems *ngIf="isoverview"></swe-filteritems>
  </div>

  <swe-table [data]="articles" [loading]="isloading" *ngIf="activetab==0">
    <ng-container *ngFor="let groupby of groupedby | keyvalue">
      <table class="table" *ngIf="(groupby.key==0&&salaryaccess>0)||(groupby.key==1&&invoiceaccess>0)">
        <thead>
          <tr class="bg-light">
            <th colspan="10">{{groupby.value.header}}</th>
          </tr>
          <tr>
            <th>{{languageService.getItem(1235)}}</th>
            <th>{{languageService.getItem(1236)}}</th>
            <th>{{languageService.getItem(1244)}}</th>
            <th>{{languageService.getItem(1245)}}</th>
            <th>{{groupby.key==0 ? languageService.getItem(901) : ''}}</th>
            <th [ngClass]="{'swe-table-marker-top swe-table-marker-left': groupby.key==0}">{{groupby.key==0 ? languageService.getItem(1365) : ''}}</th>
            <th [ngClass]="{'swe-table-marker-top': groupby.key==0}">{{groupby.key==0 ? languageService.getItem(1366) : ''}}</th>
            <th [ngClass]="{'swe-table-marker-top': groupby.key==0}">{{groupby.key==0 ? languageService.getItem(1367) : ''}}</th>
            <th [ngClass]="{'swe-table-marker-top swe-table-marker-right': groupby.key==0}">{{groupby.key==0 ? languageService.getItem(1368) : ''}}</th>
            <th>{{groupby.key==0 ? languageService.getItem(1272) : languageService.getItem(901)}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let article of groupby.value.items">
            <tr (click)="article.open=!article.open" [ngStyle]="{'cursor': article.UnderlyingList.length>0 ? 'pointer': 'auto'}" [ngClass]="{'list-group-item-warning': article.Id==settingService.lastarticle}">
              <td style="width:19%;">
              <a href="javascript:void(0);" class="swe-no-link" *ngIf="article.UnderlyingList.length>0"><i class="bi bi-swe-fw" [ngClass]="{'bi-chevron-right': !article.open, 'bi-chevron-down': article.open}"></i></a>
              <a href="javascript:void(0);" class="swe-no-link ms-1" (click)="goto(article.Id, $event)">{{article.Name}}</a>
              </td>
              <td style="width:9%;">{{article.PayType}}</td>
              <td style="width:9%;">{{article.Quantity}}</td>
              <td style="width:9%;">{{article.Price}}</td>
              <td style="width:9%;">{{groupby.key==0 ? article.Sum : ''}}</td>
              <td [ngClass]="{'swe-table-marker-left': groupby.key==0}" style="width:9%;">{{groupby.key==0 ? article.ExtraEmployerFee : ''}}</td>
              <td style="width:9%;">{{groupby.key==0 ? article.ExtraVacationPercentage : ''}}</td>
              <td style="width:9%;">{{groupby.key==0 ? article.ExtraPensionPercentage : ''}}</td>
              <td [ngClass]="{'swe-table-marker-right': groupby.key==0}" style="width:9%;">{{groupby.key==0 ? article.ExtraGeneralPercentage : ''}}</td>
              <td style="width:9%;">{{article.Total}}</td>
            </tr>
            <ng-container *ngIf="article.open">
              <tr *ngFor="let underlying of article.UnderlyingList" class="swe-bg-grayed-out">
                <td style="width:28%;" colspan="2">
                  <table class="table mb-0">
                    <tr>
                      <td style="width: 24%;">
                        <a class="swe-no-link" href="javascript:void(0);" [routerLink]="'/timereports/' + underlying.TimereportId"><i class="bi bi-swe-fw bi-clock-fill"></i></a>
                        <a *ngIf="!islevel" class="swe-no-link" href="javascript:void(0);" [routerLink]="'/levels/' + underlying.LevelId"><i class="bi bi-swe-fw bi-diagram-3-fill"></i></a>
                        <a *ngIf="!isuser" class="swe-no-link" href="javascript:void(0);" [routerLink]="'/users/' + underlying.UserId"><i class="bi bi-swe-fw bi-person-fill"></i></a>
                      </td>
                      <td *ngIf="!islevel" style="width: 38%;vertical-align:top;">{{underlying.LevelName}}</td>
                      <td *ngIf="!isuser" style="width: 38%;vertical-align: top;">{{underlying.Fullname}}</td>
                    </tr>
                  </table>
                </td>
                <td style="width:9%;">{{underlying.Quantity}}<i class="bi bi-swe-fw bi-exclamation-circle text-danger ms-3" [attr.title]="languageService.getItem(1361)" *ngIf="underlying.FixedValue>0"></i></td>
                <td style="width:9%;">{{underlying.Price}}</td>
                <td style="width:9%;">{{groupby.key==0 ? underlying.Sum : ''}}</td>
                <td [ngClass]="{'swe-table-marker-left': groupby.key==0}" style="width:9%;">{{groupby.key==0 ? underlying.ExtraEmployerFee : ''}}</td>
                <td style="width:9%;">{{groupby.key==0 ? underlying.ExtraVacationPercentage : ''}}</td>
                <td style="width:9%;">{{groupby.key==0 ? underlying.ExtraPensionPercentage : ''}}</td>
                <td [ngClass]="{'swe-table-marker-right': groupby.key==0}" style="width:9%;">{{groupby.key==0 ? underlying.ExtraGeneralPercentage : ''}}</td>
                <td style="width:9%;">{{underlying.Total}}</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
        <tfoot>
          <tr>
            <th style="text-align:right;" colspan="5">&nbsp;</th>
            <th [ngClass]="{'swe-table-marker-left swe-table-marker-right swe-table-marker-bottom': groupby.key==0}" style="text-align:right;" colspan="4">&nbsp;</th>
            <th>{{groupby.key==0 ? sumsalary : suminvoice}}</th>
          </tr>
        </tfoot>
      </table>
    </ng-container>
    <table class="table table-borderless" *ngIf="articles&&articles.length>0&&salaryaccess>0&&invoiceaccess>0">
      <thead>
        <tr class="bg-light">
          <th colspan="8">{{languageService.getItem(1282)}}</th>
          <th colspan="2">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width:19%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;">&nbsp;</td>
          <td style="width:9%;"><b>{{languageService.getItem(1272)}}</b></td>
          <td style="width:9%;">{{sumsalary}}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td><b>{{languageService.getItem(1277)}}</b></td>
          <td>{{suminvoice}}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td><b>{{languageService.getItem(1278)}}</b></td>
          <td>{{sumtb}}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td><b>{{languageService.getItem(1279)}}</b></td>
          <td>{{sumtg}} %</td>
        </tr>
      </tbody>
    </table>
  </swe-table>

  <swe-table [data]="sumarticles" [loading]="isloading" *ngIf="activetab!=0">
    <table class="table">
      <thead>
        <tr class="bg-light">
          <th>&nbsp;</th>
          <th>{{languageService.getItem(1272)}}</th>
          <th>{{languageService.getItem(1277)}}</th>
          <th>{{languageService.getItem(1278)}}</th>
          <th>{{languageService.getItem(1279)}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sumarticle of sumarticles">
          <td>{{sumarticle.name}}</td>
          <td>{{sumarticle.salary}}</td>
          <td>{{sumarticle.invoice}}</td>
          <td>{{sumarticle.tb}}</td>
          <td>{{sumarticle.tg}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>&nbsp;</th>
          <th>{{sumsalary}}</th>
          <th>{{suminvoice}}</th>
          <th>{{sumtb}}</th>
          <th>{{sumtg}}</th>
        </tr>
      </tfoot>
    </table>
  </swe-table>
</swe-card>
