import { Component, OnChanges, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PropertyService } from '../../_services/property.service';
import { PermissionService } from '../../_services/permission.service';
import { ViewCacheService } from '../../_services/viewcache.service';


@Component({
  selector: 'swe-userdata',
  templateUrl: './userdata.component.html'
})
export class UserDataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;
  @Output() userdataChange = new EventEmitter<any>();
  @ViewChild('sweSaveElement') saveElement: ElementRef;

  private _subscriptions: Subscription[] = [];
  private _user: any = {};
  private _loading: boolean = false;
  private _alreadyloaded: boolean = false;

  //Message
  private _senduser: any = null;
  private _hasCell: boolean = false;
  private _hasEmail: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService,
    private propertyService: PropertyService,
    private viewCacheService: ViewCacheService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this._subscriptions.push(settingService.onViewRefresh$
      .subscribe((refresh) => {

        if (refresh.type == 'userbelonging_save') {
          this.load();
        }

      }));

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save();
      })); 
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    if (!this.open) {
      //Must be loaded when closed because of permissions in other components
      this.load(true);
      this._alreadyloaded = true;
    }

    
  }

  ngOnChanges() {
    if (this.open) {
      this.load();
    }
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get user() {
    return this._user;
  }

  //Message
  public get hasCell() {
    return this._hasCell;
  }
  public get hasEmail() {
    return this._hasEmail;
  }
  public get editmode() {
    return this.id > 0 ? this.settingService.editMode : true;
  }


  /*Methods*/
  public profiledone() {
    if (this._user.Access > 1) {
      let element = this.saveElement.nativeElement;
      element.click();
    }
  }
  public load(override: boolean = false) {

    if (!this.open && !override) { return; }
    if (this._alreadyloaded) {
      this._alreadyloaded = false;
      return;
    }

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/users/' + this.id, 'GET')
      .subscribe(res => {
        if (res) {

          this._user = res;

          if (this._user.Id > 0) {
            this.preload(this._user.Id);
          }

          this._loading = false;

          this.userdataChange.emit({
            Id: this._user.Id,
            NoBelonging: !this._user.HasBelonging,
            ICalUrl: this._user.ICalUrl,
            ICalLevelUrl: this._user.ICalLevelUrl,
            SubscriptionsAccess: this._user.SubscriptionsAccess,
            Created: this._user.Created,
            BookingAccess: this._user.BookingAccess,
            NewTimereportAccess: this._user.NewTimereportAccess,
            NewAbscentTimereportAccess: this._user.NewAbscentTimereportAccess,
            RequestAccess: this._user.RequestAccess,
            RequestUserIdList: this._user.RequestUserIdList,
            UserMarkedDateAccess: this._user.UserMarkedDateAccess,
            UserMarkedDateComment: this._user.UserMarkedDateComment,
            StatisticAccess: this._user.StatisticAccess,
            ContractAccess: this._user.ContractAccess,
            TimeBankAccess: this._user.TimeBankAccess,
            LasAccess: this._user.LasAccess,
            EmploymentAccess: this._user.EmploymentAccess,
            LogAccess: this._user.LogAccess,
            ImpersonateAccess: this._user.ImpersonateAccess,
            ArticleSalaryAccess: this._user.ArticleSalaryAccess,
            ArticleInvoiceAccess: this._user.ArticleInvoiceAccess,
            ContractId: this._user.ContractId,
            ContractValidSince: this._user.ContractValidSince,
            TimeBankId: this._user.TimeBankId,
            LasId: this._user.LasId,
            BreakpointWeekday: this._user.BreakpointWeekday,
            BreakpointHour: this._user.BreakpointHour,
            IsActiveNow: this._user.IsActiveNow,
            IsActiveText: this._user.IsActiveText
          });
        }
      });
  }
  public save() {

    if (!this.propertyService.validate(this._user.Profile)) { return; }

    let verb = 'POST';
    let path = '/api/v1/users/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;
    }

    let dto:any = {};
    dto.Profile = this._user.Profile;
    dto.IsActive = this._user.IsActive;
    dto.IsLockedOut = this._user.IsLockedOut;
    dto.MainLevelId = this._user.MainLevelId;
    dto.ExternalId = this._user.ExternalId;
    dto.Image = this._user.Image;

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {
          let type = 'success';
          if (response.body != this.languageService.getItem(43)) {
            type = 'warning';
          }
          this.alertService.Add({ message: response.body, type });
          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
          else {
            if (this.id == this.permissionService.user.Id) {

              let reloadObj = 0;
              this.permissionService.reload().subscribe((res) => {
                reloadObj++;
                if (reloadObj == 2) {
                  this.load();
                }
              });

            }
            else {
              this.load();
            }
          }
        }
      });
  }

  public delete() {

    this.dataService.tokenRequest('/api/v1/users/' + this.id, 'DELETE', {}, 'text')
      .subscribe(res => {
        if (res) {

          this.alertService.Add({ message: res, type: 'success' });

          this.router.navigateByUrl('/users');
        }
      });
  }

  public upload = function (file) {
    if (file && file.length > 0 && this.id > 0) {
      //Upload
      let content = file.split('|');

      let dto = {
        Name: content[0],
        Data: content[1]
      };

      
      this.dataService.tokenRequest('/api/v1/users/' + this.id + '/image', 'PUT', dto, 'text')
        .subscribe(res => {

          //Reload
          this.load();

          this.alertService.Add({ message: res, type: 'success' });
        });
      
    }
    else if (file == null && this.id > 0) {
      //Delete
      this.dataService.tokenRequest('/api/v1/users/' + this.id + '/image', 'DELETE', {}, 'text')
        .subscribe(res => {

          //Reload
          this.load();

          this.alertService.Add({ message: res, type: 'success' });
        });
    }

  }
  public documentcontainer(image: any) {
    return {
      clientOnly: false,
      allowedPrefix: 'png, jpg, jpeg, jfif, gif',
      folder: 'userimages',
      document: image,
      showimage: true,
      hidedownload: true
    };
  }
  public activation() {

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/activation', 'Put', {}, 'text')
      .subscribe((res) => {
        let type = 'warning';
        if (res == this.languageService.getItem(936) || res == this.languageService.getItem(937)) {
          type = 'success';
        }
        this.alertService.Add({ message: res, type });

        this.load();
      });


  }
  public send(msgtype, e) {
    e.stopPropagation();

    if (this._user != null) {

      let url = '/messages/send/';

      if (msgtype == 1) {
        url += 'email/';
      }
      else if (msgtype == 3) {
        url += 'sms/';
      }


      this.viewCacheService.add('message_users', [this._senduser]);
      this.viewCacheService.add('message_disableTo', true);

      this.router.navigate([url]);
    }

  }

  //Functions
  private preload(userId) {

    this.dataService.tokenRequest('/api/v1/users/' + userId + '/small', 'GET')
      .subscribe((res) => {

        this._senduser = res;
        this._hasCell = this._senduser.Cell.length > 0;
        this._hasEmail = this._senduser.Email.length > 0;
      });
  }

}
