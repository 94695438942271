<swe-pageheader [header]="languageService.getItem(113)"></swe-pageheader>
  <div>
    <input class="form-control mr-sm-2" [(ngModel)]="search" (ngModelChange)="onSearch()" type="search" [placeholder]="languageService.getItem(13)" aria-label="languageService.getItem(13)">
  </div>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-start" *ngFor="let group of settings">
    <swe-card [header]="group.Header" [(open)]=" group.Open" [hasCollapse]="true" [hasRefresh]="false" [hasSave]="true" (saveChange)="save()">
      <span swe-rightcommand>
        <i class="bi-swe-pull-right bi swe-click bi-alphabet-uppercase bi-swe-fw" [ngClass]="{'text-primary': showReadableName}" (click)="toggleReadable($event)"></i>
      </span>
      <ng-container *ngFor="let setting of group.Settings" [ngSwitch]="setting.Type">
        <i class="bi bi-swe-fw bi-swe bi-swe-pull-right swe-click" [ngClass]="{'bi-question-circle-fill': !showhelp, 'bi-x-lg':showhelp}" (click)="setting.showhelp=!setting.showhelp" [attr.title]="title" *ngIf="setting.Description"></i>
        <div class="swe-info-text mt-2" *ngIf="setting.showhelp">
          <p>{{setting.Description}}</p>
        </div>
        <swe-element [type]="'System.List'" [label]="showReadableName ? setting.ReadableName ?? setting.Name : setting.Name" [statusLabel]="2" [(model)]="setting.Value" [items]="getList(setting)" [container]="container(setting)" [(changed)]="setting.Changed" *ngSwitchCase="'System.List'"></swe-element>
        <swe-element [type]="'System.MultiList'" [label]="showReadableName ? setting.ReadableName ?? setting.Name : setting.Name" [statusLabel]="2" [(model)]="setting.Value" [items]="getList(setting)" [container]="container(setting)" [(changed)]="setting.Changed" *ngSwitchCase="'System.MultiList'"></swe-element>
        <swe-element [type]="setting.Type" [label]="showReadableName ? setting.ReadableName ?? setting.Name : setting.Name" [statusLabel]="2" [(model)]="setting.Value" [container]="container(setting)" [(changed)]="setting.Changed" *ngSwitchDefault></swe-element>
      </ng-container>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
      </div>
    </swe-card>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-start] on columns-->
