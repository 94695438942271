import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';
import { PropertyService } from '../../_services/property.service';
import { AlertService } from '../../_services/alert.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ListService } from '../../_services/list.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-templatedetail',
  templateUrl: './templatedetail.component.html'
})
export class TemplateDetailComponent implements OnInit, OnDestroy {

  private _id: number = 0;
  private _template: any = {};
  private _weekdays: any[] = [];
  private _properties: any[] = [];
  private _newsetting: any = {};
  private _newbookingprofile: any = {};
  private _loading: boolean = false;
  private _plustimetypes: any[] = [];
  private _neutraltimetypes: any[] = [];
  private _minustimetypes: any[] = [];
  private _calcweekdays: any[] = [];
  private _midnightoptions: any[] = [];
  private _types: any[] = [];
  private _weekOneTypes: any[] = [];
  private _parents: any[] = [];
  private _parent: number = 0;
  private _parentname: string = '';
  private _selectedIndex: number = -1;
  private _selectedSetting: any;
  private _calculationYear: number;
  private _calculationYearHours: number = 0;
  private _iterations: any[] = [];
  private _subscriptions: Subscription[] = [];
  private _schedulingoptions: any[] = [];
  //Convert to Shifts
  private _shiftprecisions: any[] = [];
  private _shiftprecision: number = 2;
  private _shiftbreak: number = 0;
  private _shiftmealbreak: boolean = false;


  @ViewChild('sweSaveElement') saveElement: ElementRef;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private settingService: SettingService,
    private propertyService: PropertyService,
    private alertService: AlertService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    //Reset
    this.settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this._parent = Number(params.get('parent'));
    });
  }


  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {

    this.init();
    this.manageload(this._parent > 0 ? this._parent : this._id);

    this._subscriptions.push(this.settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save();
      })); 
  }


  //Properties
  public get id() {
    return this._id;
  }
  public get parent() {
    return this._parent;
  }
  public get template() {
    return this._template;
  }
  public get weekdays() {
    return this._weekdays;
  }
  public get properties() {
    return this._properties;
  }
  public get newsetting() {
    return this._newsetting;
  }
  public get isloading() {
    return this._loading;
  }
  public get timetypes() {
    return this.getTimeTypes([0, 1]);
  }
  public get calcweekdays() {
    return this._calcweekdays;
  }
  public get midnightoptions() {
    return this._midnightoptions;
  }
  public get types() {
    return this._types;
  }
  public get weekOneTypes() {
    return this._weekOneTypes;
  }
  public get parents() {
    return this._parents;
  }
  public get selectedIndex() {
    return this._selectedIndex;
  }
  public get selectedSetting() {
    return this._selectedSetting;
  }
  public get calculationYear() {
    return this._calculationYear;
  }
  public get calculationYearHours() {
    return this._calculationYearHours;
  }
  public get schedulingoptions() {
    return this._schedulingoptions;
  }
  //Convert to Shifts
  public get shiftprecisions() {
    return this._shiftprecisions;
  }
  public get shiftprecision() {
    return this._shiftprecision;
  }
  public set shiftprecision(val) {
    this._shiftprecision = val;
  }
  public get shiftbreak() {
    return this._shiftbreak;
  }
  public set shiftbreak(val) {
    this._shiftbreak = val;
  }
  public get shiftmealbreak() {
    return this._shiftmealbreak;
  }
  public set shiftmealbreak(val) {
    this._shiftmealbreak = val;
  }




  //Methods
  public getConfirmBodyText() {
    return this.languageService.getItem(1421).replace('{0}', this._template.Name);
  }
  public page(e, index) {
    e.stopPropagation();
    this._selectedIndex += index;
    this._selectedSetting = this._template.Settings[this._selectedIndex];
    this.template.Settings.forEach((setting) => {
      setting.IsEdit = false;
    });
    this._selectedSetting.IsEdit = true;

  }
  public closeEdit(e) {
    if (e) {
      e.stopPropagation();
    }
    this._selectedIndex = 0;
    this._selectedSetting.IsEdit = false;
    this._selectedSetting = null;
  }
  public editSetting(setting, index) {
    if (setting.IsEdit) {
      this.closeEdit(null);
      return;
    }
    this.template.Settings.forEach((setting) => {
      setting.IsEdit = false;
    });
    setting.IsEdit = true;
    this._selectedSetting = setting;
    this._selectedIndex = index;

  }

  public load() {
    this.manageload(this.id);
  }
  private manageload(templateId) {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/bookingtemplates/' + templateId, 'GET', {})
      .subscribe((res) => {

        this._template = res;
        this._template.OffDuty = this.generalService.formatdecimal(this._template.OffDuty);
        this._template.Vacation = this.generalService.formatdecimal(this._template.Vacation);
        this._template.EmploymentRate = this.generalService.formatdecimal(this._template.EmploymentRate);
        this._template.NumberOfHours = this.generalService.formatdecimal(this._template.NumberOfHours);
        if (this._template.TimeTypeFactors && this._template.TimeTypeFactors.length > 0) {
          this._template.TimeTypeFactors.forEach((timetype) => {
            const hours = timetype.Minutes /60
            timetype.Hours = this.generalService.formatdecimal(hours);
          });
        }
        if (this._template) {
          this._template.Settings.forEach((setting) => {

            //Properties
            setting.Properties.forEach((bookingproperty) => {
              let property = this.propertyService.getProperty(bookingproperty.PropertyId);
              if (property) {
                bookingproperty.Type = property.Type;
                bookingproperty.Items = property.Items;
              }
            });

            if (setting.LevelName && setting.LevelName.length > 0) {
              //Setting has overrideable Level
              setting.CurrentLevelName = setting.LevelName;
              setting.CurrentLevelPath = setting.LevelPath;
            }
            else {
              //Use Template Level
              setting.CurrentLevelName = this._template.LevelName;
              setting.CurrentLevelPath = this._template.LevelPath;
            }

            let iterationObj = this.listService.find(this._iterations, 'Id', setting.Iteration);
            if (iterationObj) {
              setting.IterationName = iterationObj.Name;
            }

          });

          //Parent
          if (this._parent > 0 && this._id == 0) {
            this._template.Id = 0;
            this._template.ParentId = this._parent;
            this._template.Name += ' - ' + this.languageService.getItem(1220);
          }

          

          this.calculateYearly();
        }

        this._loading = false;
      });
  }
  private calculateYearly() {

    let start = this.dateTimeService.formatWithTimeZone(new Date(this._calculationYear, 0, 1, 0, 0, 0));
    let end = this.dateTimeService.formatWithTimeZone(new Date(this._calculationYear + 1, 0, 1, 0, 0, 0));

    let filter = {
      Start: start,
      End: end
    };

    this.dataService.tokenRequest('/api/v1/bookingtemplates/' + this.id + '/calculatehours', 'POST', filter)
      .subscribe((res) => {
        this._calculationYearHours = res.Value;
      });

  }
  public isTimeVisible(time) {
    return (time != '0001-01-01T00:00:00');
  }
  public addSetting(event, setting = null) {
    event.stopPropagation();
    if (this._template && this._template.Settings) {
      this.template.Settings.forEach((setting) => {
        setting.IsEdit = false;
      });
      if (setting != null) {
        const index = this._template.Settings.indexOf(setting);
        this._newsetting = {
          ...setting
        }
        this._newsetting.IsNew = true;
        this._newsetting.Id = 0;
        this._newsetting.IsEdit = true;
        this._newsetting.Properties = this._newsetting.Properties.map(x => ({ ...x, Id: 0 }));
        this._newsetting.Activities = this._newsetting.Activities.map(x => ({ ...x, Id: 0 }));
        this._newsetting.Breaks = this._newsetting.Breaks.map(x => ({ ...x, Id: 0 }));
        this._template.Settings.splice(index + 1, 0, this._newsetting);
      }
      else {
        if (this._neutraltimetypes.length > 0) {
          this.newsetting.TimeType = this._neutraltimetypes[0].Id;
        }
        this._template.Settings.push(this._newsetting);
      }

      this._selectedSetting = this._newsetting;
      this._selectedIndex = this._template.Settings.length - 1;

      this.createNewSetting();
    }
  }
  public deleteSetting(setting) {
    if (setting.Id > 0) {
      //Exists on server
      setting.IsDeleted = !setting.IsDeleted;
    }
    else {
      //Exists only on client => remove
      let index = this._template.Settings.indexOf(setting);
      if (index > -1) {
        this._template.Settings.splice(index, 1);
      }
    }
  }
  public getTime(time) {
    if (!time) {
      return "";
    }
    const date = new Date(time);
    return this.dateTimeService.format(date, 'HH:mm');
  }

  public getTimeSpan(start, end) {
    if (!start || !end) {
      return "";
    }
    const startDate = new Date(start);
    const endDate = new Date(end);
    return this.dateTimeService.format(startDate, 'HH:mm') + '-' + this.dateTimeService.format(endDate, 'HH:mm');
  }

  public connect() {

    this.router.navigate(['/admin/templates/0/parent/' + this._id], { replaceUrl: true });
  }
  public copy() {
    this.save(true);
  }
  public save(iscopy: boolean = false) {

    let verb = 'Post';
    let path = '/api/v1/bookingtemplates/'
    if (this._id > 0 && !iscopy) {
      verb = 'Put';
      path += this._id;
    }

    if (this._template && this._template.Settings) {
      let error = false;
      this._template.Settings.forEach((setting) => {
        setting.IsError = false;

        if (!error) {
          const dStart = new Date(setting.Start);
          const compareStart = new Date(1970, 1, 1, dStart.getHours(), dStart.getMinutes(), dStart.getSeconds());
          const totalBreak = setting.Breaks.reduce((sum, BreakObj) => sum + BreakObj.Break, 0)
          const compareEnd = new Date(1970, 1, 1, dStart.getHours() + setting.Hours, dStart.getMinutes() + setting.Minutes + totalBreak, dStart.getSeconds());
          if (this._template.Type == 1) {
            //Remove breaks from needs template
            setting.Breaks = [];
          }
          //Shift is over 24h so break will always be in range
          if (((setting.Hours * 60) + setting.Minutes) < 1440) {
            for (let i = 0; i < setting.Breaks.length; i++) {
              const breakObj = setting.Breaks[i];
              if (breakObj.Break == 0) {
                continue;
              }
              const dBreak = new Date(breakObj.BreakStart);
              let compareBreakStart = new Date(1970, 1, 1, dBreak.getHours(), dBreak.getMinutes(), dBreak.getSeconds());
              let compareBreakEnd = new Date(1970, 1, 1, compareBreakStart.getHours(), compareBreakStart.getMinutes() + breakObj.Break, compareBreakStart.getSeconds());

              if (compareBreakEnd <= compareEnd && compareBreakStart >= compareStart) {
                continue;

              }
              if (compareEnd.getDate() != compareStart.getDate()) {
                compareBreakEnd = new Date(1970, 1, compareEnd.getDate(), compareBreakEnd.getHours(), compareBreakEnd.getMinutes(), 0);
                compareBreakStart = new Date(1970, 1, compareEnd.getDate(), compareBreakStart.getHours(), compareBreakStart.getMinutes(), 0);
                if (compareBreakEnd <= compareEnd && compareBreakStart >= compareStart) {
                  continue;
                }
              }

              this.alertService.Add({ type: 'danger', message: this.languageService.getItem(788) });
              setting.IsError = error = true;
              return;
            }

            if (setting.Activities.length > 0) {
              for (let i = 0; i < setting.Activities.length; i++) {
                const activity = setting.Activities[i];
                if (activity.IsDeleted) {
                  continue;
                }
                const aStart = new Date(activity.Start);
                let activityStart = new Date(1970, 1, 1, aStart.getHours(), aStart.getMinutes(), aStart.getSeconds());
                let activityEnd = new Date(activityStart.getTime() + activity.EndMinutes * 60000);
                if (activityStart >= compareStart && activityEnd <= compareEnd) {
                  continue;
                }
                if (compareEnd.getDate() != compareStart.getDate()) {
                  activityStart = new Date(1970, 1, compareEnd.getDate(), activityStart.getHours(), activityStart.getMinutes(), 0);
                  activityEnd = new Date(1970, 1, compareEnd.getDate(), activityEnd.getHours(), activityEnd.getMinutes(), 0);
                  if (activityStart < activityEnd && activityStart >= compareStart && activityEnd <= compareEnd) {
                    continue;
                  }
                }
                this.alertService.Add({ type: 'danger', message: this.languageService.getItem(740) });
                setting.IsError = error = true;
                return;
              }
            }
          }
          if (!Number.isInteger(setting.Hours)) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1409).replace('{0}', this.languageService.getItem(624)) });
            setting.IsError = error = true;
            return;

          }
          if (!Number.isInteger(setting.Minutes)) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1409).replace('{0}', this.languageService.getItem(623)) });
            setting.IsError = error = true;
            return;

          }

          if (setting.Hour <= 0 && setting.Minutes <= 0) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(662) });
            setting.IsError = error = true;
            return;
          }
          if (compareStart > compareEnd) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(662) });
            setting.IsError = error = true;
            return;
          }
          if (setting.Max < setting.Quantity) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1022) });
            setting.IsError = error = true;
            return;
          }
          if (setting.Min > setting.Quantity) {
            this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1023) });
            setting.IsError = error = true;
            return;
          }

          if (!setting.Weekdays || setting.Weekdays == 0) {
            setting.Weekdays = 127 //All days
          }
          

          if (!setting.Activity || setting.Activity.length == 0) {
            setting.Activity = 0;
          }
          if (!setting.TimeType || setting.TimeType.length == 0) {
            setting.TimeType = 0;
          }
        }
      });
      if (this._template.Name.length > 50) {
        this.alertService.Add({ type: 'danger', message: this.languageService.getItem(732).replace('{0}', this.languageService.getItem(509)).replace('{1}', '50') });
        return;
      }
      if (error) {
        return;
      }
    }

    this.dataService.tokenRequest(path, verb, this._template, 'text', 'response')
      .subscribe((response) => {

        if (response) {

          this.alertService.Add({ type: 'success', message: response.body });

          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true })
              .finally(() => {
                this.load();
                this._selectedIndex = -1;
                this._selectedSetting = null;
              });
          }
          else {
            this.load();
            this._selectedIndex = -1;
            this._selectedSetting = null;
          }
        }

      });

  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/bookingtemplates/' + this._id, 'Delete', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  }
  public getActivityName(activityId) {
    let activity = this.generalService.activitytypes.find(x => x.Id == activityId);

    if (activity) {
      return activity.Name;
    }
    return "";
  }

  public manageReload(filteritem) {
    if (filteritem.ReloadItems) {
      let property = this.propertyService.getProperty(filteritem.Id);
      if (property) {
        filteritem.Items = property.Items;
      }
      filteritem.ReloadItems = false;
    }
    else {
      return filteritem.Items;
    }
  }
  public prevYear() {
    this._calculationYear--;
    this.calculateYearly();
  }
  public nextYear() {
    this._calculationYear++;
    this.calculateYearly();
  }

  public getTimetypeActivityLabel(timetypeActivity: any) {

    const timetype = this.timetypes.find(x => x.Id == timetypeActivity.TimeTypeId);
    if (timetype) {
      return timetypeActivity.Factor != 1 ? timetype.Name + ` (${this.languageService.getItem(554)} ${timetypeActivity.Factor})` : timetype.Name;
    }
    return this.languageService.getItem(806);
  }

  //Functions
  private init() {

    this._calculationYear = new Date().getFullYear();

    this.createNewSetting();

    //Calc Weekdays
    this._calcweekdays = [
      { Id: 1, Name: this.languageService.getItem(209) },
      { Id: 2, Name: this.languageService.getItem(210) },
      { Id: 3, Name: this.languageService.getItem(211) },
      { Id: 4, Name: this.languageService.getItem(212) },
      { Id: 5, Name: this.languageService.getItem(213) },
      { Id: 6, Name: this.languageService.getItem(214) },
      { Id: 0, Name: this.languageService.getItem(215) }
    ];
    //Calc Weekdays
    this._midnightoptions = [
      { Id: 1, Name: 'First' },
      { Id: 2, Name: 'Last' },
      { Id: 3, Name: 'Biggest' }
    ];

    this._types = [
      { Id: 1, Name: this.languageService.getItem(1385) },
      { Id: 2, Name: this.languageService.getItem(1400) }
    ];

    this._weekOneTypes = [
      { Id: 0, Name: this.languageService.getItem(1424) },
      { Id: 1, Name: this.languageService.getItem(1425) },
      { Id: 2, Name: this.languageService.getItem(1426) }
    ];

    this._parents = [];
    this._parentname = '';
    this.dataService.tokenRequest('/api/v1/bookingtemplates', 'GET', {})
      .subscribe((res) => {
        res.forEach((parent) => {
          if (parent.Id != this._id) {
            this._parents.push(parent);
          }
          if (parent.Id == this._parent) {
            this._parentname = parent.Name;
          }
        });
      });

    this._weekdays = [
      { Id: 2, Name: this.languageService.getItem(209).substring(0, 2) },
      { Id: 4, Name: this.languageService.getItem(210).substring(0, 2) },
      { Id: 8, Name: this.languageService.getItem(211).substring(0, 2) },
      { Id: 16, Name: this.languageService.getItem(212).substring(0, 2) },
      { Id: 32, Name: this.languageService.getItem(213).substring(0, 2) },
      { Id: 64, Name: this.languageService.getItem(214).substring(0, 2) },
      { Id: 1, Name: this.languageService.getItem(215).substring(0, 2) }
    ];

    //TimeTypes
    this.generalService.timetypes.forEach((timetype) => {
      if (timetype.Extra < 0) {
        this._minustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else if (timetype.Extra == 0) {
        this._neutraltimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else {
        this._plustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }

    });

    this.dataService.tokenRequest('/api/v1/properties/type/bookingprofile', 'GET', {})
      .subscribe((list) => {
        this._properties = list;

        this.createNewBookingProfile();
      });

    this._iterations = [
      { Id: 0, Name: this.languageService.getItem(1427) },
      { Id: 1, Name: this.languageService.getItem(363) },
      { Id: 2, Name: this.languageService.getItem(364) },
      { Id: 3, Name: this.languageService.getItem(365) },
      { Id: 4, Name: this.languageService.getItem(366) },
      { Id: 5, Name: this.languageService.getItem(367) },
      { Id: 6, Name: this.languageService.getItem(368) },
      { Id: 7, Name: this.languageService.getItem(369) },
      { Id: 8, Name: this.languageService.getItem(370) },
      { Id: 9, Name: this.languageService.getItem(371) },
      { Id: 10, Name: this.languageService.getItem(372) },
      { Id: 11, Name: this.languageService.getItem(1446).replace("{0}", 11) },
      { Id: 12, Name: this.languageService.getItem(1446).replace("{0}", 12) }
    ];

    this._schedulingoptions = [
      { Id: 0, Name: this.languageService.getItem(1479) },
      { Id: 1, Name: this.languageService.getItem(1480) },
      { Id: 2, Name: this.languageService.getItem(1481) }
    ];

    this._shiftprecisions = [
      { Id: 0, Name: '5 minuter' },
      { Id: 1, Name: 'Kvart' },
      { Id: 2, Name: 'Halvtimme' },
      { Id: 3, Name: 'Timme' },
    ];
  }
  private getTimeTypes(signs: any[]) {

    let list: any[] = [];

    signs.forEach((sign) => {

      if (list.length > 0) {
        list.push({ Name: '-----' });
      }

      if (sign > 0) {
        this._plustimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else if (sign == 0) {
        this._neutraltimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else {
        this._minustimetypes.forEach((item) => {
          list.push(item);
        });
      }

    });

    return list;
  }
  private createNewSetting() {
    this._newsetting = {
      Status: 0,
      WeekNr: 0,
      Quantity: 0,
      Min: 0,
      Max: 0,
      Hours: 0,
      Minutes: 0,
      Start: '0001-01-01T00:00:00',
      End: '0001-01-01T00:00:00',
      IsActive: true,
      IsNew: true,
      IsEdit:true,
      Properties: [],
      Activities: [],
      Breaks: [{Break: 0}]
    };
  }
  private createNewBookingProfile() {

    let propertyId = 0;
    if (this._properties.length > 0) {
      propertyId = this._properties[0].Id;
    }

    this._newbookingprofile = {
      PropertyId: propertyId,
      IsEdit: true
    };
  }

}
