<swe-card [header]="languageService.getItem(1238)" [open]="true" [hasCollapse]="settingService.isDevice(['xs','sm'])" [hasRefresh]="false">
  <swe-card [header]="languageService.getItem(6)" [hasRefresh]="false" [open]="true">
    <swe-element [type]="'System.Text'" [label]="languageService.getItem(1235)" [statusLabel]="1" [(model)]="article.Name"></swe-element>
    <swe-element [type]="'System.Text'" [label]="languageService.getItem(1297)" [statusLabel]="1" [(model)]="article.Code"></swe-element>
    <swe-element [type]="'System.List'" [label]="languageService.getItem(1294)" [statusLabel]="1" [(model)]="article.ArticleCategory" [items]="articlecategories"></swe-element>
    <swe-element [type]="'System.List'" [label]="languageService.getItem(613)" [statusLabel]="1" [(model)]="article.Type" [items]="types"></swe-element>
    <swe-element [type]="'System.Text'" [label]="languageService.getItem(1236)" [statusLabel]="1" [(model)]="article.PayType"></swe-element>
    <swe-element [type]="'System.List'" [label]="languageService.getItem(1244)" [statusLabel]="1" [(model)]="article.QuantityPropertyId" [items]="article.QuantityPropertyList"></swe-element>
    <swe-element [type]="'System.List'" [label]="languageService.getItem(1245)" [statusLabel]="1" [(model)]="article.PricePropertyId" [items]="article.PricePropertyList"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1361)" [statusLabel]="1" [(model)]="article.Value" [hint]="languageService.getItem(1362)"></swe-element>
    <swe-element [type]="'System.Date'" [label]="languageService.getItem(1313)" [statusLabel]="1" [(model)]="article.Start"></swe-element>
    <swe-element [type]="'System.Date'" [label]="languageService.getItem(1314)" [statusLabel]="1" [(model)]="article.End"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(511)" [statusLabel]="1" [(model)]="article.Sort"></swe-element>
  </swe-card>
  <swe-card [header]="languageService.getItem(614)" [hasRefresh]="false" [open]="false">
    <swe-element [type]="'System.FilteredMultiple'" [label]="languageService.getItem(953)" [statusLabel]="1" [(model)]="article.Contracts" [items]="article.ContractList" [container]="{ min: 2, max: 8}" *ngIf="article.ContractList&&article.ContractList.length>0"></swe-element>
    <swe-element [type]="'System.CheckboxList'" [label]="languageService.getItem(1204)" [statusLabel]="1" [(model)]="article.StaffType" [items]="article.StaffTypeList" [container]="{isbit:true, min:2}" [optional]="true" [optionalValue]="0" *ngIf="article.StaffTypeList&&article.StaffTypeList.length>0"></swe-element>
    <swe-element [type]="'System.CheckboxList'" [label]="languageService.getItem(1207)" [statusLabel]="1" [(model)]="article.SalaryType" [items]="article.SalaryTypeList" [container]="{isbit:true, min:2}" [optional]="true" [optionalValue]="0" *ngIf="article.SalaryTypeList&&article.SalaryTypeList.length>0"></swe-element>
    <swe-element [type]="'System.FilteredMultiple'" [label]="languageService.getItem(1065)" [statusLabel]="1" [(model)]="article.Categories" [items]="article.CategoryList" [container]="{min:2, max: 8}" *ngIf="article.CategoryList&&article.CategoryList.length>0"></swe-element>
    <swe-element [type]="'System.CheckboxList'" [label]="languageService.getItem(925)" [statusLabel]="1" [(model)]="article.EmploymentTypes" [items]="article.EmploymentTypeList" [container]="{min:2}" *ngIf="article.EmploymentTypeList&&article.EmploymentTypeList.length>0"></swe-element>
  </swe-card>
  <!--Buttons-->
  <div class="d-grid gap-2">
    <button class="btn btn-primary" (click)="save()" *ngIf="permissionService.permissions.Articles>1"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
    <button class="btn btn-success" (click)="save(true)" *ngIf="id>0&&permissionService.permissions.Articles>1"><i class="bi bi-journals"></i>&nbsp;{{languageService.getItem(206)}}</button>
    <swe-confirm *ngIf="article.Id>0 && permissionService.permissions.Articles>2" [body]="languageService.getItem(1239)" (acceptChange)="delete()">
      <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>
