<swe-pageheader></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-6 float-start">
    <swe-card header="{{languageService.getItem(1078)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
      <ng-container *ngIf="ispersonal">
        <div class="alert alert-info">
          {{languageService.getItem(1110)}}
        </div>
      </ng-container>
      <ng-container *ngIf="groupname.length>0">
        <div class="alert alert-secondary">
          [{{groupname}}]
        </div>
      </ng-container>
      <div class="mb-3">
        <label>{{languageService.getItem(1081)}}</label>
        <div class="p-1 rounded border">
          <ng-container *ngFor="let item of items">
            <div class="d-flex mt-1">
              <swe-element [bottomMargin]="0" [(model)]="item.Chosen" (modelChange)="manageRequestType($event)" [label]="item.Name" [statusLabel]="4" [customCols]="0" [labelToRight]="true" [type]="'System.Boolean'" [disabled]="item.Id==1"></swe-element>
            </div>
          </ng-container>
        </div>
      </div>
      <swe-element [(model)]="header" [label]="languageService.getItem(1079)" [statusLabel]="2" [type]="'System.String'" *ngIf="isvisible(requesttype,4)"></swe-element>
      <swe-element [(model)]="question" [label]="languageService.getItem(1080)" [statusLabel]="2" [container]="{rows:8}" [type]="'System.TextArea'" [hideCounter]="true">
        <div swe-footer>
          <span class="small">{{languageService.getItem(331)}} {{question.length}}</span>&nbsp;&nbsp;&nbsp;<span class="small" *ngIf="(permissionService.permissions.Sms>0)&&isvisible(requesttype,2)">{{languageService.getItem(332)}} {{smscounter}}</span>
        </div>
      </swe-element>
      <swe-element [(model)]="delayed" [label]="delayedLabel" [statusLabel]="2" [type]="'System.DateTime'" *ngIf="(permissionService.permissions.Sms>0)&&isvisible(requesttype,2)"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="send()"><i class="bi bi-send"></i>&nbsp;{{languageService.getItem(329)}}</button>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-6 float-start">
    <swe-card header="{{languageService.getItem(576)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
      <swe-element [(model)]="chosenoptions" [label]="languageService.getItem(1091)" [statusLabel]="2" [items]="options" [type]="'System.MultiList'"></swe-element>
      <swe-card header="{{languageService.getItem(1108)}}" [color]="'swe'" [open]="false" [hasRefresh]="false" *ngIf="(permissionService.permissions.Sms>0)&&isvisible(requesttype,2)">
        <span swe-hits>&nbsp;({{missing.length}})</span>
        <swe-element [label]="languageService.getItem(1108)" [items]="missing" [type]="'System.MultiList'"></swe-element>
      </swe-card>
      <swe-element [(model)]="chosenusers" [label]="languageService.getItem(2)" [statusLabel]="2" [items]="users" [type]="'System.MultiList'" [disabled]="users.length==1">
        <div swe-footer>
          <span class="small">{{languageService.getItem(794)}} {{chosenusers.length}} ({{users.length}})</span>
        </div>
      </swe-element>
    </swe-card>
  </div>
</div>
