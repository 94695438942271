<div *ngIf="listutility&&datePagerOpt">
  <div class="btn-group align-top d-md-none" *ngIf="listutility.toggleaccess">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe btn-swe-primary" [ngClass]="{'active': listutility.allchecked}" (click)="listutility.checkall(!listutility.allchecked)" *ngIf="listutility.toggleaccess" [attr.disabled]="listutility.rows.length==0 ? true : null">
        <span><i class="bi bi-check-lg"></i></span>
      </button>
      <ng-container [ngTemplateOutlet]="listoptions"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="extra"></ng-container>
    <ng-container [ngTemplateOutlet]="after" *ngIf="after"></ng-container>
  </div>
</div>

<div *ngIf="listutility" sweFixedHeader [ngClass]="{'swe-reset-sticky-top': resetsticky}" [noleftpadding]="true">
  <div class="btn-group align-top" [ngClass]="{'d-md-inline-block d-none': datePagerOpt}" *ngIf="listutility.toggleaccess">
    <div class="dropdown">
      <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe btn-swe-primary" [ngClass]="{'active': listutility.allchecked}" (click)="listutility.checkall(!listutility.allchecked)" *ngIf="listutility.toggleaccess" [attr.disabled]="listutility.rows.length==0 ? true : null">
        <span><i class="bi bi-check-lg"></i></span>
      </button>
      <ng-container [ngTemplateOutlet]="listoptions"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="extra"></ng-container>
  </div>
  <swe-datepager *ngIf="datePagerOpt" [type]="datePagerOpt.type" (dateChange)="search();listutility.checkall(false)" [disabled]="loading" [hideTime]="datePagerOpt.hideTime"></swe-datepager>
  <div [ngClass]="{'d-md-inline-block d-none': datePagerOpt}" *ngIf="after">
    <ng-container [ngTemplateOutlet]="after"></ng-container>
  </div>
  <div class="btn-group align-top mx-2" *ngIf="all">
    <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
  </div>
  <div class="btn-group ms-1 align-top" *ngIf="more">
    <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
  <swe-filteritems class="d-none d-md-inline"></swe-filteritems>
</div>

<div *ngIf="!listutility&&datePagerOpt" class="d-md-none">
  <ng-container [ngTemplateOutlet]="extra"></ng-container>
  <ng-container [ngTemplateOutlet]="after" *ngIf="after"></ng-container>
</div>

<div *ngIf="!listutility" sweFixedHeader [ngClass]="{'swe-reset-sticky-top': resetsticky}" [noleftpadding]="true">
  <div [ngClass]="{'d-md-inline d-none': datePagerOpt}">
    <ng-container [ngTemplateOutlet]="extra"></ng-container>
  </div>
  <swe-datepager *ngIf="datePagerOpt" [type]="datePagerOpt.type" (dateChange)="search();" [disabled]="loading" [hideTime]="datePagerOpt.hideTime"></swe-datepager>
  <div [ngClass]="{'d-md-inline d-none': datePagerOpt}" *ngIf="after">
    <ng-container [ngTemplateOutlet]="after"></ng-container>
  </div>
  <div class="btn-group align-top mx-2" *ngIf="all">
    <button class="btn btn-danger" (click)="search(false, true)">{{languageService.getItem(340)}}</button>
  </div>
  <div class="btn-group ms-1 align-top" *ngIf="more">
    <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
  <swe-filteritems class="d-none d-md-inline"></swe-filteritems>
</div>


