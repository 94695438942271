<swe-pageheader [header]="languageService.getItem(108)"><span swe-hits>&nbsp;(#{{id}})</span></swe-pageheader>
<div class="row d-lg-block d-flex py-2">
  <div class="col-12 col-lg-4 float-start order-lg-0" [ngClass]="{'order-1': !cards.data, 'order-0': cards.data}">
    <swe-leveldata [id]="id" [(open)]="cards.data" (leveldataChange)="manageLeveldata($event)"></swe-leveldata>
  </div>
  <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.settings, 'order-0': cards.settings}">
    <swe-levelsettings [id]="id" [(open)]="cards.settings" [leveldata]="leveldata" *ngIf="settingaccess>0"></swe-levelsettings>
  </div>
  <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.relations, 'order-0': cards.relations}">
    <swe-levelrelations [id]="id" [(open)]="cards.relations" [leveldata]="leveldata" *ngIf="id>0&&relationaccess>0"></swe-levelrelations>
  </div>
  <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.users, 'order-0': cards.users}">
    <swe-levelusers [id]="id" [(open)]="cards.users" *ngIf="id>0&&useraccess>0"></swe-levelusers>
  </div>
  <div class="col-12 col-lg-4 float-start order-lg-0" [ngClass]="{'order-1': !cards.bookings, 'order-0': cards.bookings}">
    <swe-levelbookings [id]="id" [(open)]="cards.bookings" [leveldata]="leveldata" [type]="1" *ngIf="id>0&&bookingaccess>1&&permissionService.permissions.EmploymentPlan>0"></swe-levelbookings>
  </div>
  <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.bookings, 'order-0': cards.bookings}">
    <swe-levelbookings [id]="id" [(open)]="cards.bookings" [leveldata]="leveldata" [type]="0" *ngIf="id>0&&bookingaccess>1"></swe-levelbookings>
  </div>
  <ng-container *ngFor="let obj of objecttoobjects; index as i">
    <div class="col-12 col-lg-8 float-start order-lg-0" *ngIf="id>0" [ngClass]="{'order-1': !cards.objects || !cards.objects[i], 'order-0': cards.objects && cards.objects[i]}">
      <swe-objecttoobjects [id]="id" [data]="obj" [open]="!cards.objects ? false : cards.objects[i]" (openChange)="!cards.objects ? false : cards.objects[i] = $event"></swe-objecttoobjects>
    </div>
  </ng-container>
  <div class="col-12 col-lg-8 float-start order-lg-0" [ngClass]="{'order-1': !cards.logs, 'order-0': cards.logs}">
    <swe-card header="{{languageService.getItem(400)}}" [(open)]="cards.logs" [hasRefresh]="false" icon="bi-clock-history">
      <swe-generallogs [id]="id" [(open)]="cards.systemlogs" [type]="'level'" [data]="leveldata" *ngIf="id>0&&logaccess>0"></swe-generallogs>
      <swe-generalmessages [id]="id" [(open)]="cards.messagelogs" [type]="'level'" [data]="leveldata" *ngIf="id>0&&logaccess>0"></swe-generalmessages>
    </swe-card>
  </div>
</div>
  <div class="d-md-none d-inline-block">
    <app-botmenu [actions]="tabs" *ngIf="leveldata"></app-botmenu>
  </div>
