<div class="list-group-item" [ngClass]="{'d-flex': settingService.timeline.groupby != -3}" *ngIf="settingService.timeline.showgraph">
  <div [ngClass]="{'col-2 col-md-2': settingService.timeline.groupby != -3,'col-12': settingService.timeline.groupby == -3}" *ngIf="settingService.timeline.groupby != -3">
    <div class="me-2" *ngIf="showBookingTemplate()">
      <div class="mb-1" [ngClass]="{'btn-group': settingService.isDevice(['xxl'])}">
        <button class="btn" [ngClass]="{'btn-primary': templatetab==1&&templates2.length>0, 'btn-outline-primary': templatetab!=1}" (click)="changetemplatetab()" *ngIf="templates1.length>0">{{languageService.getItem(1009)}}</button>
        <button class="btn" [ngClass]="{'btn-primary': templatetab==2&&templates1.length>0, 'btn-outline-primary': templatetab!=2}" (click)="changetemplatetab()" *ngIf="templates2.length>0">{{languageService.getItem(1408)}}</button>
      </div>
      <ng-container *ngIf="templatetab==1">
        <swe-element [type]="'System.FilteredCheckboxList'" [label]="languageService.getItem(1009)" [statusLabel]="0" [(model)]="settingService.timeline.templateIdList1" (modelChange)="calculateTemplate()" [items]="templates1"></swe-element>
      </ng-container>
      <ng-container *ngIf="templatetab==2">
        <swe-element [type]="'System.FilteredCheckboxList'" [label]="languageService.getItem(1408)" [statusLabel]="0" [(model)]="settingService.timeline.templateIdList2" (modelChange)="calculateTemplate()" [items]="templates2"></swe-element>
      </ng-container>
      <ng-container *ngIf="settingService.timeline.groupby==0&&templatetab==2&&settingService.timeline.templateIdList2&&settingService.timeline.templateIdList2.length>0">
        <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1013)" [statusLabel]="2" [style]="'width:65px'" [(model)]="settingService.timeline.adjustweeknrstart" (modelChange)="calculateTemplate()"></swe-element>
        <span class="small fst-italic">{{languageService.getItem(1284)}}</span>
        <button class="btn btn-primary mb-3 ms-2" (click)="createShiftsByTemplate()">{{languageService.getItem(1283)}}</button>
      </ng-container>
      <swe-element [type]="'System.CheckboxList'" [label]="languageService.getItem(6)" [statusLabel]="2" [(model)]="settingIdList" (modelChange)="calculateTemplate()" [items]="templatesettings" *ngIf="templatetab==1&&settingService.timeline.templateIdList1&&settingService.timeline.templateIdList1.length>0"></swe-element>
    </div>
    &nbsp;
  </div>
  <div [ngClass]="{'col-10 col-md-10': settingService.timeline.groupby != -3,'col-12': settingService.timeline.groupby == -3}">
    <swe-shiftschart [rows]="shiftchartrows"
                     [comparedata]="shiftchartcomparedata"
                     [start]="settings.start"
                     [end]="settings.end"
                     [showhour]="settings.showhour"
                     [precision]="settingService.timeline.precision"
                     [show]="settingService.timeline.timelineshow"
                     *ngIf="settings">
    </swe-shiftschart>
  </div>
</div>
<div sweFixedHeader [index]="2" class="p-0" [ngClass]="{'swe-reset-sticky-top': settings&&settings.isdragged}">
  <div class="list-group-item px-0" [ngClass]="{'d-flex': settingService.timeline.groupby != -3}" *ngIf="settings">
    <!--Resource-->
    <div [ngClass]="{'col-2 col-md-4 col-lg-3 col-xl-2': settingService.timeline.groupby != -3 && menucollapsed, 'col-5 col-md-4 col-lg-3 col-xl-2': settingService.timeline.groupby != -3 && !menucollapsed, 'col-12': settingService.timeline.groupby == -3}">
      <div class="btn-group align-top" *ngIf="permissionService.permissions.GroupByLevel||(permissionService.permissions.GroupBy&&permissionService.permissions.GroupBy.length>0)">
        <div class="dropdown ps-1">
          <swe-dropdown [label]="groupbyname" (isOpenChange)="toggle()" [isOpen]="groupbymenu" [collapsed]="menucollapsed">
            <ul class="dropdown-menu" [ngClass]="{'show': groupbymenu}">
              <li class="dropdown-item swe-click" (click)="change(-3)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.groupby==-3}"></i>{{languageService.getItem(846)}}</a></li>
              <li class="dropdown-item swe-click" (click)="change(0)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.groupby==0}"></i>{{languageService.getItem(2)}}</a></li>
              <li class="dropdown-item swe-click" (click)="change(-1)" *ngIf="permissionService.permissions.GroupByLevel"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.groupby==-1}"></i>{{languageService.getItem(3)}}</a></li>
              <li class="dropdown-item swe-click" (click)="change(-2)" *ngIf="permissionService.permissions.GroupByLevel"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.groupby==-2}"></i>{{languageService.getItem(793)}}</a></li>
              <li class="dropdown-item swe-click" (click)="change(-4)" *ngIf="permissionService.permissions.Activity"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.groupby==-4}"></i>{{languageService.getItem(395)}}</a></li>
              <li class="dropdown-item swe-click" (click)="change(groupby)" *ngFor="let groupby of permissionService.permissions.GroupBy"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':settingService.timeline.groupby==groupby}"></i>{{propertyService.getProperty(groupby).Name}}</a></li>
              <li class="dropdown-divider"></li>
              <li class="dropdown-item swe-click" (click)="changeSortBy(1)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg': settingService.timeline.sortby==1}"></i>{{languageService.getItem(1143)}}</a></li>
              <li class="dropdown-item swe-click" (click)="changeSortBy(2)"><a><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg': settingService.timeline.sortby==2}"></i>{{languageService.getItem(1396)}}</a></li>
            </ul>
          </swe-dropdown>
        </div>
      </div>
      <div class="btn-group me-1 float-end d-none d-lg-inline-flex">
        <button class="btn btn-swe" (click)="settingService.timeline.collapserow=!settingService.timeline.collapserow;changeSumOptions()" [attr.title]="languageService.getItem(1428)">
          <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !settingService.timeline.collapserow, 'bi-check-square': settingService.timeline.collapserow}"></i></span>
          <span><i class="bi bi-swe-fw bi-swe bi-arrows-collapse"></i></span>
        </button>
      </div>
      <div class="btn-group me-1 float-end d-none d-lg-inline-flex" *ngIf="permissionService.permissions.GroupProfile">
        <button class="btn btn-swe" (click)="settingService.timeline.showprofile=!settingService.timeline.showprofile;changeSumOptions()" [attr.title]="languageService.getItem(409)">
          <span><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !settingService.timeline.showprofile, 'bi-check-square': settingService.timeline.showprofile}"></i></span>
          <span><i class="bi bi-swe-fw bi-swe bi-person-lines-fill"></i></span>
        </button>
      </div>
    </div>
    <!--Shifts-->
    <div class="position-relative" [ngClass]="{'col-10 col-md-8 col-lg-9 col-xl-10': settingService.timeline.groupby != -3 && menucollapsed, 'col-7 col-md-8 col-lg-9 col-xl-10': settingService.timeline.groupby != -3 && !menucollapsed, 'col-12': settingService.timeline.groupby == -3}" [ngStyle]="{'height': settings.headerheight + 'px'}">
      <!--Year/Month/Week line-->
      <ng-container *ngFor="let day of settings.days;index as i">
        <div style="position:absolute;border-left: 1px solid #999999;background-color:white;height:40px;overflow-x:hidden;"
             [ngStyle]="{'left': day.left + '%'}"
             *ngIf="day.main&&(settings.showyear||settings.days.length==1||monthshow(day.date,i)||weekshow(day.date,i))">
          <span class="swe-timeline-header d-none d-md-inline" *ngIf="settings.showyear&&yearshow(day.date,i)">&nbsp;{{day.date.getFullYear()}}</span>
          <span class="swe-timeline-header d-none d-md-inline" *ngIf="settings.days.length==1||monthshow(day.date,i)" (click)="getMonth(day.date)">&nbsp;{{dateTimeService.monthname(day.date,3)}}</span>
          <span class="swe-timeline-header" *ngIf="settings.showweek&&weekshow(day.date,i)" (click)="getWeek(day.date)">&nbsp;v.{{dateTimeService.weeknumber(day.date)}}</span>
        </div>
      </ng-container>
      <!--Day line-->
      <ng-container *ngFor="let day of settings.days;index as i">
        <div style="position:absolute;top:24px;border-left: 1px solid #999999;background-color:white;"
             [ngStyle]="{'width': 100/settings.days.length + '%','left': day.left + '%'}"
             (dblclick)="!settings.showhour&&create(day.date, $event)"
             *ngIf="settings.showday&&day.main">
          <span class="swe-timeline-header" [attr.title]="day.date.getDate() + ' ' + dateTimeService.monthname(day.date)" (click)="getDay(day.date)">
            <span>&nbsp;{{day.date.getDate()}}</span>
            <span class="d-none d-md-inline">&nbsp;{{dateTimeService.weekdayname(day.date,2)}}</span>
            <span class="text-success d-none d-md-inline" *ngIf="!settings.showhour&&all">&nbsp;{{generalService.formatdecimal(settings.sum[i])}} {{settingService.timeline.sumoptions==0 ? 'h' : languageService.getItem(724)}}</span>
          </span>
        </div>
      </ng-container>
      <!--Hour line-->
      <ng-container *ngFor="let hour of settings.hours;index as i">
        <div [ngClass]="{'d-none d-md-inline-block':(hour % 3)}"
             style="position:absolute;top:44px;border-left: 1px solid #999999;background-color:white;max-height:20px;"
             [ngStyle]="{'width': 100/settings.hours.length + '%','left': i*100*settings.hourfactor/settings.hours.length + '%'}"
             (dblclick)="create(settings.cols[i].date, $event)"
             [title]="generalService.formatdecimal(settings.sum[i]) + ' h'"
             class="overflow-hidden"
             *ngIf="settings.showhour">
          <span class="swe-timeline-header ps-md-1">{{hour}}</span>&nbsp;<span class="text-success d-none d-md-inline-block" *ngIf="all&&(settingService.timeline.precision>-3)">{{generalService.formatdecimal(settings.sum[i])}} h</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
