import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { AlertService } from '../../_services/alert.service';
import { ListService } from '../../_services/list.service';
import { SettingService } from '../../_services/setting.service';



@Component({
  selector: 'app-systemtables',
  templateUrl: './systemtables.component.html'
})
export class SystemTablesComponent implements OnInit, OnDestroy  {

  private _subscriptions: Subscription[] = [];
  private _debugmode: any;
  private _clientId: any;
  private _clientIdPassword: any;
  private _secret: any;
  private _userId: any;
  private _validminutes: any;
  private _authtoken: any;
  private _generalId: any;
  private _decodedId: any;
  private _notifyText: string = '';
  private _roles: any[] = [];
  private _roleId: number = 0;
  private _levels: any[] = [];
  private _levelId: number = 0;
  private _userroles: any[] = [];
  private _userlevels: any[] = [];
  private _userconnections: any[] = [];
  private _advancedbelonging: boolean = false;
  private _tables: any[] = [];

  constructor(
    public languageService: LanguageService,
    private generalService: GeneralService,
    private dataService: DataService,
    private permissionService: PermissionService,
    private alertService: AlertService,
    private listService: ListService,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();

    this._debugmode = localStorage.getItem('debug');

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this._tables.forEach((table) => {

          if (table.open) {
            this.save(table);
          }
        });
        
      })); 
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this.init();
  }


  //Properties
  public get adminmode() {
    return this.settingService.adminmode;
  }
  public get debugmode() {
    return this._debugmode;
  }
  public set debugmode(val) {
    this._debugmode = val;
  }
  public get clientId() {
    return this._clientId;
  }
  public set clientId(val) {
    this._clientId = val;
  }
  public get clientIdPassword() {
    return this._clientIdPassword;
  }
  public set clientIdPassword(val) {
    this._clientIdPassword = val;
  }
  public get secret() {
    return this._secret;
  }
  public get userId() {
    return this._userId;
  }
  public set userId(val) {
    this._userId = val;
  }
  public get validminutes() {
    return this._validminutes;
  }
  public set validminutes(val) {
    this._validminutes = val;
  }
  public get authtoken() {
    return this._authtoken;
  }
  public get generalId() {
    return this._generalId;
  }
  public set generalId(val) {
    this._generalId = val;
  }
  public get decodedId() {
    return this._decodedId;
  }
  public get notifyText() {
    return this._notifyText;
  }
  public set notifyText(val) {
    this._notifyText = val;
  }
  public get roles() {
    return this._roles;
  }
  public get roleId() {
    return this._roleId;
  }
  public set roleId(val) {
    this._roleId = val;
  }
  public get levels() {
    return this._levels;
  }
  public get levelId() {
    return this._levelId;
  }
  public set levelId(val) {
    this._levelId = val;
  }
  public get userroles() {
    return this._userroles;
  }
  public get userlevels() {
    return this._userlevels;
  }
  public get userconnections() {
    return this._userconnections;
  }
  public get advancedbelonging() {
    return this._advancedbelonging;
  }
  public get tables() {
    return this._tables;
  }


  //Methods
  public changeDebug() {
    if (this._debugmode) {
      localStorage.setItem('debug', 'true');
    }
    else {
      localStorage.removeItem('debug');
    }
  }

  public changeAdminRule() {
    this.settingService.changeAdminMode();
  }

  public clearcache() {
    this.dataService.tokenRequest('/api/v1/system/cache/', 'DELETE', {})
      .subscribe((res) => {
        this.generalService.reloadRoles().subscribe();
        this.generalService.reloadLevelGroups().subscribe();
        this.generalService.reloadReports().subscribe();

        this.permissionService.reload().subscribe();

        window.open('/', '_self');

        this.alertService.Add({ type: 'success', message: this.languageService.getItem(119) });
      });
  }
  public createClient() {

    this._secret = '';

    let dto = {
      ClientId: this._clientId,
      Password: this._clientIdPassword
    };

    this.dataService.tokenRequest('/api/v1/system/client/', 'POST', dto, 'text')
      .subscribe((res) => {

        this._secret = res;
        
      });
  }
  public createToken() {

    this._authtoken = '';

    let dto = {
      UserId: this._userId,
      ValidInMinutes: this._validminutes
    };

    this.dataService.tokenRequest('/api/v1/system/token/', 'POST', dto, 'text')
      .subscribe((res) => {

        this._authtoken = res;

      });
  }
  public parseId() {

    this._decodedId = '';

    let dto = {
      Id: this._generalId
    };

    this.dataService.tokenRequest('/api/v1/system/parse/', 'POST', dto, 'text')
      .subscribe((res) => {

        this._decodedId = res;

      });
  }
  public push() {

    let dto = {
      text: this._notifyText
    };

    this.dataService.tokenRequest('/api/v1/PushNotification/test', 'POST', dto , 'text', 'response')
      .subscribe(response => {

        if (response) {
          //Message to client
          this.alertService.Add({ message: response.body, type: 'success' });
        }

      });
  }
  public loadBelonging() {

    this.dataService.tokenRequest('/api/v1/system/belongings', 'GET', {})
      .subscribe((res) => {

        this._userroles = res.Roles;
        this._userlevels = res.Levels;
        this._userconnections = [];
        Object.keys(res.Connections).forEach((roleId) => {
          let connection = {
            Role: this.listService.find(this._roles, 'Id', parseInt(roleId)),
            Levels: []
          }

          res.Connections[roleId].forEach((levelId) => {
            connection.Levels.push(this.listService.find(this._levels, 'Id', levelId));
          });

          this._userconnections.push(connection);
        });

      });
  }
  public saveBelonging() {

    if (this._roleId <= 0) { return; }

    let url = '/api/v1/system/belongings/roles/' + this._roleId;
    if (this._levelId > 0) {
      url += '/levels/' + this._levelId;
    }

    this.dataService.tokenRequest(url, 'POST', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ message: res, type: 'success' });
        this.loadBelonging();

        this._roleId = 0;
        this._levelId = 0;
      });
  }
  public getTable(table, reload:boolean = false) {

    if (!reload) {
      table.open = !table.open;
    }

    if (table.open) {

      this.dataService.tokenRequest('/api/v1/system/tables/' + table.ClassName, 'GET', {})
        .subscribe((res) => {

          table.headers = [];
          table.rows = [];
          table.footer = {};

          res.forEach((row, index) => {
            if (index == 0) {
              table.headers = row.Properties;
            }

            if (table.ColumnDesc.length > 0) {
              for (let i = 0; i < row.Properties.length; i++) {
                this.manageColumn(i, table, row, false);
              }
            }

            if (row.Id != 0) {
              table.rows.push(row);
            }
            else {
              table.footer = row;
            }
          });
        });

    }
  }
  public getItems(table, name) {
    return this.listService.formatArray(table.Lists, [name], 'Name')[0].List;
  }
  public getGroupByItems(table, name) {
    let list = this.listService.formatArray(table.Lists, [name], 'Name')[0].List;

    let groupbyList: any[] = [];
    list.forEach((item) => {
      let groupby = this.listService.find(groupbyList, 'Name', item.Extra);
      if (groupby) {
        groupby.Items.push({ Id: item.Id, Name: item.Name });
      }
      else {
        groupbyList.push({ Name: item.Extra, Items: [{ Id: item.Id, Name: item.Name }] });
      }
    });

    return groupbyList;
  }
  public manageColumn(idx, table, row, clear = true) {

    if (clear) {
      row.HasColumnDesc = false;
      row.ColumnDesc = "";
      row.ColumnIndex = -1;
    }

    if (table.ColumnDesc.length > 0) {
      table.ColumnDesc.forEach((desc) => {
        if (desc.Key == idx) {
          if (desc.Value == row.Properties[idx].Value) {
            row.HasColumnDesc = true;
            row.ColumnDesc = desc.Extra;
            row.ColumnIndex = idx;
          }
        }
      })
    }
  }
  public save(table) {
    let list = [...table.rows];

    let isloaded = false;
    table.footer.Properties.forEach((property) => {

      let isEmptyList = (property.Type == "System.List" && property.Value == 0);

      if (property.Type == "System.DateTime") {
        if (property.Value != "0001-01-01T00:00:00") {
          isloaded = true;
          list.push(table.footer);
        }
      }
      else if (((property.Type == "System.List" && !isEmptyList) || (property.Value != null && property.Value.length > 0)) && !isloaded) {
        isloaded = true;
        list.push(table.footer);
      }
    });

    this.dataService.tokenRequest('/api/v1/system/tables/' + table.ClassName, 'PUT', list, 'text')
      .subscribe((res) => {

        if (res.length > 0) {
          table.rows = [];
          this.getTable(table, true);
          this.alertService.Add({ type: 'success', message: res });
        }
      });
  }




  //Functions
  private init() {

    this._advancedbelonging = this.permissionService.permissions.AdvancedBelonging;

    this.dataService.tokenRequest('/api/v1/system/tables/', 'GET', {})
      .subscribe((res) => {

        this._tables = [];
        res.forEach((item) => {

          //Add extra fields
          item.visible = false;
          item.open = false;
          item.headers = [];
          item.rows = [];
          item.footer = {};

          this._tables.push(item);
        });

      });

    this._roles = [];
    this.dataService.tokenRequest('/api/v1/roles/', 'GET', {})
      .subscribe((res) => {
        this._roles = res;
      });

    this._levels = [];
    this.dataService.tokenRequest('/api/v1/levels/', 'GET', {})
      .subscribe((res) => {
        this._levels = res;
      });

  }


}
