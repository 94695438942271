import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TokenService {


  /*Properties*/
  public get authBasic(): string {
    return "Basic " + btoa("SparrowWeb:SparrowWeb");
  }
  public get authBearer(): string {
    let authtoken = localStorage.getItem('authtoken');

    if (authtoken !=null && authtoken.length > 0) {
      return "Bearer " + authtoken;
    }

    return "";
  }



  /*Methods*/
  public saveTokens(res) {
    localStorage.setItem('isauth', res.IsAuth);
    localStorage.setItem('isotp', res.IsOtp);
    localStorage.setItem('otptoken', res.OtpToken);
    localStorage.setItem('authtoken', res.AuthToken);
    localStorage.setItem('refreshtoken', res.RefreshToken);
    localStorage.setItem('version', res.Version);
    localStorage.setItem('originaluser', res.OriginalUser);
  }
  public getToken(name) {
    let token = localStorage.getItem(name);
    if (!token) { token = ''; }

    return token;
  }
  public setToken(name, value) {
    if (value != null && value.length > 0) {
      localStorage.setItem(name, value);
    }
  }
  public refreshTokens(res) {
    localStorage.setItem('authtoken', res.AuthToken);
    localStorage.setItem('refreshtoken', res.RefreshToken);
  }
  public clearTokens() {
    localStorage.removeItem('isauth');
    localStorage.removeItem('isotp');
    localStorage.removeItem('otptoken');
    localStorage.removeItem('authtoken');
    localStorage.removeItem('refreshtoken');
  }
}
