import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { AlertService } from '../../../_services/alert.service';
import { SettingService } from '../../../_services/setting.service';
import { GeneralService } from '../../../_services/general.service';



@Component({
  selector: 'swe-contractdata',
  templateUrl: './contractdata.component.html'
})
export class ContractDataComponent implements OnInit, OnChanges {

  @Input() id: number;
  @Output() contractdataChange = new EventEmitter<any>();

  private _contract: any = {};
  private _types: any[] = [];
  private _issalary = true;
  private _isinvoice = true;
  private _frameworkcontracts: any[] = [];
  private _timebanks: any[] = [];
  private _las: any[] = [];
  private _qualifyingDays: any[] = [];
  private _employerfeedesc: string = '';
  private _employerfee: number = 0;
  private _employerfeeerror: boolean = false;
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private router: Router,
    private location: Location
  ) {

    
    
  }


  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.load();
  }


  //Properties
  public get contract() {
    return this._contract;
  }
  public get types() {
    return this._types;
  }
  public get issalary() {
    return this._issalary;
  }
  public get isinvoice() {
    return this._isinvoice;
  }
  public get frameworkcontracts() {
    return this._frameworkcontracts;
  }
  public get timebanks() {
    return this._timebanks;
  }
  public get las() {
    return this._las;
  }
  public get qualifyingDays() {
    return this._qualifyingDays;
  }
  public get employerfeedesc() {
    return this._employerfeedesc;
  }
  public set employerfeedesc(val) {
    this._employerfeedesc = val;
  }
  public get employerfee() {
    return this._employerfee;
  }
  public set employerfee(val) {
    this._employerfee = val;
  }
  public get employerfeeerror() {
    return this._employerfeeerror;
  }




  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/contracts/' + this.id, 'GET', {})
      .subscribe((res) => {

        this._contract = res;

        this.changeType();

      });

  }
  public save() {

    if (this._employerfeedesc.length > 0) {
      this._contract.EmployerFeeList.push({
        Desc: this._employerfeedesc,
        Fee: this._employerfee
      });

      this._employerfeedesc = '';
      this._employerfee = 0;
      this._employerfeeerror = false;
    }

    this._contract.WorkdayHours = this._contract.WorkdayHours ? this._contract.WorkdayHours : 0;
    this._contract.HoursPerMonth = this._contract.HoursPerMonth ? this._contract.HoursPerMonth : 0;
    this._contract.Pension = this._contract.Pension ? this._contract.Pension : 0;
    this._contract.Vacation = this._contract.Vacation ? this._contract.Vacation : 0;
    this._contract.General = this._contract.General ? this._contract.General : 0;

    let verb = 'Post';
    let path = '/api/v1/contracts/'
    if (this.id > 0) {
      verb = 'Put';
      path += this.id;
    }

    this.dataService.tokenRequest(path, verb, this._contract, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          this.generalService.reloadContracts().subscribe();
        }
        else {
          this.load();
        }

      });

  };
  public changeType() {

    this._issalary = (this._contract.Type & 1) == 1;
    this._isinvoice = (this._contract.Type & 2) == 2;

    this.contractdataChange.emit({
      ContractId: this._contract.Id,
      TimeBankId: this._contract.TimeBankId,
      LasId: this._contract.LasId,
      OBAccess: this._contract.OBAccess,
      ATKAccess: this._contract.ATKAccess,
      TimeBankAccess: this._contract.TimeBankAccess,
      LasAccess: this._contract.LasAccess,
      PriceAccess: this._contract.PriceAccess,
      QualifyingDaySettingId: this._contract.QualifyingDaySettingId,
      IsSalary: this._issalary,
      IsInvoice: this._isinvoice
    });

  }
  public insertemployerfee() {

    if (this._employerfeedesc.length == 0) {
      this._employerfeeerror = true;
      return;
    }

    this._contract.EmployerFeeList.push({
      Desc: this._employerfeedesc,
      Fee: this._employerfee
    });

    this._employerfeedesc = '';
    this._employerfee = 0;
    this._employerfeeerror = false;
  }
  public addemployerfee(employerfee) {
    employerfee.IsDeleted = false;
  }
  public deleteemployerfee(employerfee) {
    employerfee.IsDeleted = true;
  }

  public copy() {

    let verb = 'Post';
    let path = '/api/v1/contracts/copy/'

    this.dataService.tokenRequest(path, verb, this._contract, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          this.generalService.reloadContracts().subscribe();
        }

        this.load();
      });

  }

  public delete() {

    this.dataService.tokenRequest('/api/v1/contracts/' + this.id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  };
  



  //Functions
  private init() {

    //Types
    this._types = [];
    this._types.push({ Id: 1, Name: this.languageService.getItem(1004) });
    this._types.push({ Id: 2, Name: this.languageService.getItem(1005) });

    this.generalService.frameworkcontracts.forEach((frameworkcontract) => {

      this._frameworkcontracts.push({ Id: frameworkcontract.Key, Name: frameworkcontract.Value });

    });

    this.generalService.timebanks.forEach((timebank) => {

      this._timebanks.push({ Id: timebank.Key, Name: timebank.Value });

    });

    this.generalService.las.forEach((las) => {

      this._las.push({ Id: las.Key, Name: las.Value });

    });
    this.generalService.qualifyingDays.forEach((qd) => {
      this._qualifyingDays.push({ Id: qd.Key, Name: qd.Value })
    });
  }

}
