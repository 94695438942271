import { Component, OnChanges, Input, ElementRef, ViewChild, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ListService } from '../../_services/list.service';
import { PropertyService } from '../../_services/property.service';
import { relatedObject } from '../../layout/profile.component';



@Component({
  selector: 'swe-timereportdata',
  templateUrl: './timereportdata.component.html'
})
export class TimereportDataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() othertimereports:any[] = [];
  @Input() open: boolean;
  @Input() userid: number = 0;
  @Input() type: number = 0;
  @Output() typeChange = new EventEmitter<any>();
  @Output() headerChange = new EventEmitter<any>();
  @Output() idChange = new EventEmitter<any>();
  @Output() timereportdataChange = new EventEmitter<any>();
  @ViewChild('sweSaveElement') saveElement: ElementRef;

  private _subscriptions: Subscription[] = [];
  private _timereport: any;
  private _loading: boolean;
  private _header: string;
  private _abscent: boolean = false;
  private _coreChanged: any;
  private _isReload: boolean;
  private _groupedby: any;
  private _timereports: any[] = [];
  private _repeatdays: number;
  private _repeatend: Date;
  private _plustimetypes: any[] = [];
  private _neutraltimetypes: any[] = [];
  private _minustimetypes: any[] = [];
  private _hideHeader: boolean;
  private _headerDetail: string;
  private _user: string;
  private _popup: any[] = [{ Visible: false, Event: null, Option: 3 }];
  //RelatedObjects (to profilelist)
  private _relatedObjectList: relatedObject[] = [];

  constructor(
    public languageService: LanguageService,
    private dataService: DataService,
    private alertService: AlertService,
    public settingService: SettingService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save();
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    if (this.open) {
      this.load();
    }

  }

  /*Properties*/
  public get headerDetail() {
    return this._headerDetail;
  }
  public get loading() {
    return this._loading;
  }
  public get timereport() {
    return this._timereport;
  }
  public get header() {
    return this._header;
  }
  public get abscent() {
    return this._abscent;
  }
  public get coreChanged() {
    return this._coreChanged;
  }
  public get isReload() {
    return this._isReload;
  }
  public set isReload(val) {
    this._isReload = val;
  }
  public get groupedby() {
    return this._groupedby;
  }
  public get repeatdays() {
    return this._repeatdays;
  }
  public set repeatdays(val) {
    this._repeatdays = val;
  }
  public get repeatend() {
    return this._repeatend;
  }
  public set repeatend(val) {
    this._repeatend = val;
  }
  public get popup() {
    return this._popup;
  }
  public get serieDayList() {
    return [
      { Id: 2, Name: this.languageService.getItem(209) },
      { Id: 4, Name: this.languageService.getItem(210) },
      { Id: 8, Name: this.languageService.getItem(211) },
      { Id: 16, Name: this.languageService.getItem(212) },
      { Id: 32, Name: this.languageService.getItem(213) },
      { Id: 64, Name: this.languageService.getItem(214) },
      { Id: 1, Name: this.languageService.getItem(215) }
    ];
  }
  public get payStatusList() {
    return [
      { Id: 1, Name: 'Skickad till lön' },
      { Id: 2, Name: 'Fakturerad' }
    ];
  }
  public get hideHeader() {
    return this._hideHeader;
  }
  public get timetypes() {

    if (this.type == 3 || this._timereport.BookingType == 3) {
      //Abscent
      return this.getTimeTypes([-1]);
    }
    else if (this.id > 0) {
      //Old Timereport
      return this.getTimeTypes([1, 0]);
    }
    else {
      //New Timereport
      return this.getTimeTypes([1, 0]);
    }

  }

  public reloadOnAbscent() {
    this.load();
  }

  public bookMyselfAbscentPopUp(event) {

    if (this.permissionService.permissions.DetailWhenAbscent) {
      this._popup[0].Visible = true;
      this._popup[0].Event = event;
      this._popup[0].Option = 3;
    }
    else {
      const filter = {
        Type: 3
      };
      this.dataService.tokenRequest('/api/v1/bookings/' + this._timereport.Booking + '/users/' + this.permissionService.user.Id, 'POST', filter, 'text')
        .subscribe((res) => {

          this.alertService.Add({ message: res, type: 'success' });
          this.load();


        });
    }
  }

  public get starttimetypes() {
    let list: any[] = [];
    
    if (this.type == 3 || this._timereport.BookingType == 3) {
      //Abscent
      //list = this.getTimeTypes([-1]);
    }
    else if (this.startTime() > 0) {
      //Plus
      list = this.getTimeTypes([1, 0]);
    }
    else {
      //Minus
      list = this.getTimeTypes([-1]);
    }

    return list;
  }
  public get endtimetypes() {
    let list: any[] = [];
    
    if (this.type == 3 || this._timereport.BookingType == 3) {
      //Abscent
      //list = this.getTimeTypes([-1]);
    }
    else if (this.endTime() > 0) {
      //Plus
      list = this.getTimeTypes([1, 0]);
    }
    else {
      //Minus
      list = this.getTimeTypes([-1]);
    }

    return list;
  }

  public get user() {
    return this._user;
  }
  //RelatedObjects
  public get relatedObjectList() {
    return this._relatedObjectList;
  }


  
  /*Methods*/
  public relatedObjectChange() {
    this._relatedObjectList = [];
    this._relatedObjectList.push({ Name: 'StartTimeType', Value: this._timereport.StartTimeType });
    this._relatedObjectList.push({ Name: 'TimeType', Value: this._timereport.TimeType });
    this._relatedObjectList.push({ Name: 'EndTimeType', Value: this._timereport.EndTimeType });
  }
  public manageCoreChange(idx, val) {
    let item = this._coreChanged[idx];
    if (item != null) {
      item.Changed = (item.Original != null && item.Original != val);
    }
  }
  public profiledone() {
    if (this._timereport.Access > 1) {
      let element = this.saveElement.nativeElement;
      element.click();
    }
  }
  public load() {

    this._repeatdays = 0;
    this._repeatend = this.dateTimeService.lastDayInWeek(new Date());

    this._header = this.languageService.getItem(52);

    this._isReload = true;
    setTimeout(() => {
      this._isReload = false;
    }, 500);

    if (!this.open) { return; }

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/timereports/' + this.id + '/type/' + this.type, 'GET')
      .subscribe(res => {
        if (res) {

          if (res.BookingType == 3) {
            this._header = this.languageService.getItem(984);
            this._abscent = true;
            this.headerChange.emit(this.languageService.getItem(983));
          }
          else {
            this.headerChange.emit(this.languageService.getItem(52));
          }

          if (res.Id == 0) {
            if (!this.permissionService.permissions.EmptyBookingLevel) {
              //Storage
              let storage = localStorage.getItem('level');
              if (!storage) {
                storage = this.permissionService.permissions.BookingLevel;
              }
              if (storage) {
                let storageArr = storage.split('|');
                res.LevelId = storageArr[0];
                if (storageArr.length > 1) {
                  res.LevelName = storageArr[1];
                }
                if (storageArr.length > 2) {
                  res.LevelPath = storageArr[2];
                }
              }
            }
          }
          res.DateTimeHeader = this.dateTimeService.header(new Date(res.Start), new Date(res.End), false, true);

          this._timereport = res;
          if (res.ConfirmedProfile.length > 0) {
            this._timereport.Profile = this._timereport.ConfirmedProfile.concat(this._timereport.Profile);
          }

          this.ensureTimetypeExists(this._timereport.TimeType);
          this.ensureTimetypeExists(this._timereport.StartTimeType);
          this.ensureTimetypeExists(this._timereport.EndTimeType);

          this.timereportdataChange.emit({
            Id: this._timereport.Id,
            BookingId: this._timereport.Booking,
            UserId: this._timereport.UserId,
            ArticleSalaryAccess: this._timereport.ArticleSalaryAccess,
            ArticleInvoiceAccess: this._timereport.ArticleInvoiceAccess
          });


          this._coreChanged = {
            Status: { Original: this._timereport.Status, Changed: false },
          };


          if (this.othertimereports && this.othertimereports.length > 0) {

            this._timereports = [];

            let filter = {
              Top: 0,
              Multiple: 1,
              //Timereport
              //Timereports
              RemoveStandby: false,
              RemoveDecline: false,
              TimereportList: this.othertimereports.map(function (othertimereport) { return othertimereport.id; }).join()
            };

            this.dataService.tokenRequest('/api/v1/timereports/search', 'POST', filter)
              .subscribe(res2 => {
                res2.TimeReports.forEach(othertimereport => {
                  othertimereport.dateheader = this.dateTimeService.format(othertimereport.Start, 'HH:mm') + ' - ' + this.dateTimeService.format(othertimereport.End, 'HH:mm');

                  this._timereports.push(othertimereport);
                });

                this._groupedby = this.listService.groupByDate(this._timereports, "Start");
              });
          }
          if (this._timereport.Id != 0) {
            let info = [this.dateTimeService.format(this._timereport.ShiftStart, 'HH:mm') + ' - ' + this.dateTimeService.format(this._timereport.ShiftEnd, 'HH:mm')];
            if (this._timereport.LevelName) {
              info.push(this._timereport.LevelName);
            }
            if (this._timereport.FirstName && this._timereport.LastName) {
              this._user = `${this._timereport.FirstName} ${this._timereport.LastName}`;
            }
            else if (this._timereport.Username) {
              this._user = this._timereport.Username
            }
            this._headerDetail = info.join(', ');
            this._hideHeader = true;
          }

          this.relatedObjectChange();

          this._loading = false;
        }
      });
  }
  public resetBreak() {
    this.save(true);
  }
  public save(resetBreak: boolean = false) {    

    if (!this.validatedatetime() || !this.validatebreak()) { return; }
    if (!this.validatetimetypes()) { return; }
    if (!this.propertyService.validate(this._timereport.Profile)) { return; }

    let verb = 'POST';
    let path = '/api/v1/timereports/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;
    }

    //Manage Break
    for (let i = 0; i < this._timereport.Breaks.length; i++) {
      if (new Date(this._timereport.Breaks[i].BreakStart) <= new Date(1970, 1, 1) || this._timereport.Breaks[i].Break <= 0) {
        if (this.permissionService.permissions.BreakStart || this._timereport.Breaks[i].Break <= 0) {
          this._timereport.Breaks.splice(i, 1);
          i--;
        }
        else {
          this._timereport.Breaks[i].BreakStart = '0001-01-01T00:00:00Z';
        }
      }
    }


    let dto: any = {
      Start: this._timereport.Start,
      StartAccess: this._timereport.StartAccess,
      End: this._timereport.End,
      EndAccess: this._timereport.EndAccess,
      BreakStartAccess: this._timereport.BreakStartAccess,
      BreakAccess: this._timereport.BreakAccess,
      Breaks: this._timereport.Breaks,
      Status: this._timereport.Status,
      Profile: this._timereport.Profile,
      RemoveATK: this._timereport.RemoveATK,
      ExternalCategory: this._timereport.ExternalCategory ? this._timereport.ExternalCategory : 0,
      ResetBreak: resetBreak,
      LevelId: this.id > 0 ? 0 : this._timereport.LevelId,
      UserId: this.userid,
      BookingType: this.type,
      RepeatEnd: this._repeatend,
      RepeatDays: this._repeatdays,
      StartTimeType: (this.hasStartTime() && this._timereport.StartTimeType && this._timereport.StartTimeType != 'undefined') ? this._timereport.StartTimeType : 0,
      TimeType: this._timereport.TimeType && this._timereport.TimeType != 'undefined' ? this._timereport.TimeType : 0,
      EndTimeType: (this.hasEndTime() && this._timereport.EndTimeType && this._timereport.EndTimeType != 'undefined') ? this._timereport.EndTimeType : 0,
      SalarySent: this._timereport.SalarySent,
      InvoiceSent: this._timereport.InvoiceSent,
      OffDutyFactor: this._timereport.OffDutyFactor,
      VacationFactor: this._timereport.VacationFactor
    };

    dto["Breaks"].forEach((item) => {

      if (new Date(item.BreakStart) <= new Date(1970, 1, 1)) {
        item.BreakStart = new Date('0001-01-01T00:00:00Z');
      }
    });

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {
          this.alertService.Add({ message: response.body, type: 'success' });

          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
          else {
            this.type = 0;
            this.typeChange.emit(this.type);
            this.load();

            this.settingService.viewrefresh('reload_articles');
          }
        }
      });
  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/timereports/' + this.id, 'DELETE', {}, 'text')
      .subscribe(res => {
        if (res) {

          this.alertService.Add({ message: res, type: 'success' });

          this.location.back();
        }
      });
  }
  public manageDeleteText() {
    if (this.timereport.BookingType == 3) {
      return this.languageService.getItem(1134);
    }
    else {
      return this.languageService.getItem(1133);
    }
  }
  public managestatus(id) {

    let disabled = true;
    if (id == -1) {
      if (this.timereport.Status == 40 && this.timereport.TimereportAccess > 2) {
        disabled = false;
        return;
      }
      if (this.timereport.Status == 0 || this.timereport.Status == -1) {
        disabled = false;
        return;
      }
    }
    if (id == 40) {
      //**********************************************/
      //Timereported
      //**********************************************/
      if (this.timereport.Status < 40 && this.timereport.TimereportAccess > 1) {
        //Upgrade, need write timereport access
        disabled = false;
      }
      else if (this.timereport.Status == 40) {
        //Current status always allowed
        disabled = false;
      }
      else if (this.timereport.Status == 50 && this.timereport.ConfirmedAccess > 2) {
        //Downgrade from confirmed, need delete confirmed access
        disabled = false;
      }
      else if (this.timereport.Status == 60) {
        //Downgrade from completed, need delete confirmed access and delete completed access if confirmed exist
        if (this.listService.find(this.generalService.timereportstatus, 'Id', 50)) {
          if (this.timereport.CompletedAccess > 2 && this.timereport.ConfirmedAccess > 2) {
            disabled = false;
          }
        }
        else if (this.timereport.CompletedAccess > 2) {
          disabled = false;
        }
      }
    }

    if (id == 50) {
      //**********************************************/
      //Confirmed
      //**********************************************/
      if (this.timereport.Status < 50 && this.timereport.ConfirmedAccess > 1) {
        //Upgrade, need write confirmed access
        disabled = false;
      }
      else if (this.timereport.Status == 50) {
        //Current status always allowed
        disabled = false;
      }
      else if (this.timereport.Status == 60 && this.timereport.CompletedAccess > 2) {
        //Downgrade from completed, need delete completed access
        disabled = false;
      }
    }

    if (id == 60) {
      //**********************************************/
      //Completed
      //**********************************************/
      if (this.timereport.Status < 60 && this.timereport.CompletedAccess > 1) {
        //Upgrade, need write confirmed access
        disabled = false;
      }
      else if (this.timereport.Status == 60) {
        //Current status always allowed
        disabled = false;
      }
    }

    return disabled;
  }
  public goto(id, e) {

    e.stopPropagation();

    let idx = this.othertimereports.map(function (timereport) { return timereport.id; }).indexOf(id);

    let url = '/timereports/' + this.othertimereports[idx].id;
    if (this.type > 0) {
      url += '/user/' + this.userid;
      url += '/type/' + this.othertimereports[idx].type;
    }

    this.router.navigate([url], { replaceUrl: true });

    this.idChange.emit(id);
  }
  public manageDateTime(e) {

    if (this.permissionService.permissions.BreakStart) {

      for (let i = 0; i < this._timereport.Breaks.length; i++) {
        if (new Date(this._timereport.Breaks[i].BreakStart) > new Date(1970, 1, 1)) {
          this._timereport.Breaks[i].BreakStart = this.adjustbreakstart(new Date(this._timereport.Start), new Date(this._timereport.End), new Date(this._timereport.Breaks[i].BreakStart));
        }
      }
    }

  }
  public timePart(part) {
    let first = null;
    let last = null;

    if (part == 1) {
      //Start
      let start = this.startTime();
      if (start != 0) {
        if (start > 0) {
          //Plustime
          first = new Date(this._timereport.Start);
          last = new Date(this._timereport.ShiftStart);
        }
        else {
          //Minustime
          first = new Date(this._timereport.ShiftStart) ;
          last = new Date(this._timereport.Start);
        }
      }
    }
    else if (part == 2) {
      //Time
      first = this._timereport.ShiftStart;
      last = this._timereport.ShiftEnd;
      let start = this.startTime();
      if (start < 0) {
        first = new Date(this._timereport.Start);
      }
      let end = this.endTime();
      if (end < 0) {
        last = new Date(this._timereport.End);
      }
    }
    else if (part == 3) {
      let end = this.endTime();
      if (end != 0) {
        if (end > 0) {
          //Plustime
          first = new Date(this._timereport.ShiftEnd);
          last = new Date(this._timereport.End);
        }
        else {
          //Minustime
          first = new Date(this._timereport.End);
          last = new Date(this._timereport.ShiftEnd);
        }
      }
    }


    if (first != null && last != null) {
      return this.dateTimeService.format(first, 'HH:mm') + ' - ' + this.dateTimeService.format(last, 'HH:mm');
    }
    
    return '';
  }
  public hasStartTime() {
    return this.startTime() != 0 && this.starttimetypes.length > 0;
  }
  public hasEndTime() {
    return this.endTime() != 0 && this.endtimetypes.length > 0;
  }
  public hasDiff() {
    return (this.hasStartTime() || this.hasEndTime());
  }
  public startTime() {
    let shiftStart = new Date(this._timereport.ShiftStart);
    let timereportStart = new Date(this._timereport.Start);

    if (shiftStart <= new Date(1970, 1, 1)) {
      //New Timereport => No diff against shift
      return 0;
    }

    return (shiftStart.getTime() - timereportStart.getTime());
  }
  public endTime() {
    let shiftEnd = new Date(this._timereport.ShiftEnd);
    let timereportEnd = new Date(this._timereport.End);

    if (shiftEnd <= new Date(1970, 1, 1)) {
      //New Timereport => No diff against shift
      return 0;
    }

    return (timereportEnd.getTime() - shiftEnd.getTime());
  }


  //Functions
  private getTimeTypes(signs: any[]) {

    let list: any[] = [];

    signs.forEach((sign) => {

      if (list.length > 0) {
        list.push({ Id: -1, Name: '-----' });
      }

      if (sign > 0) {
        this._plustimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else if (sign == 0) {
        this._neutraltimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else {
        this._minustimetypes.forEach((item) => {
          list.push(item);
        });
      }

    });

    return list;
  }
  private validatedatetime() {

    let start = this._timereport.Start ? new Date(this._timereport.Start) : this._timereport.Start;
    let end = this._timereport.End ? new Date(this._timereport.End) : this._timereport.End;

    /*Manage ShiftTime*/
    if (!this.permissionService.permissions.ShiftTime) {

      start = new Date(start.getFullYear(), start.getMonth(), start.getDate());
      end = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    }

    /*Test*/
    return this.dateTimeService.validation(start, end);
  }
  private validatebreak() {

    let breaks: any[] = this._timereport.Breaks;
    

    let res = true;
    const start = new Date(this._timereport.ShiftStart != '0001-01-01T00:00:00+00:00' ? this._timereport.ShiftStart : this._timereport.Start);
    const end = new Date(this._timereport.ShiftEnd != '0001-01-01T00:00:00+00:00' ? this._timereport.ShiftEnd : this._timereport.End);
    for (let i = 0; i < breaks.length; i++) {
      let breakObj = breaks[i];

      if (this.permissionService.permissions.BreakStart) {
        if (breakObj.BreakStart != null && new Date(breakObj.BreakStart) > new Date(1970, 1, 1)) {
          if (new Date(breakObj.BreakStart) < start || end < new Date(breakObj.BreakStart)) {
            this.alertService.Add({ message: this.languageService.getItem(788), type: 'danger' });

            res = false;
            break;
          }
          else if ((new Date(breakObj.BreakStart).getTime() + (breakObj.Break * 60 * 1000)) > end.getTime()) {
            this.alertService.Add({ message: this.languageService.getItem(789), type: 'danger' });

            res = false;
            break;
          }
        }
      }
    }

    return res;
  }

  private ensureTimetypeExists(timetypeId: number) {
    if (timetypeId == 0) {
      return;
    }
    if (!this._neutraltimetypes.some(x => x.Id == timetypeId) && !this._minustimetypes.some(x => x.Id == timetypeId)) {
      this._neutraltimetypes.push({ Id: timetypeId, Name: `[${this.languageService.getItem(351)}]` });
      this._minustimetypes.push({ Id: timetypeId, Name: `[${this.languageService.getItem(351)}]` });     
    }
  }

  private validatetimetypes() {

    //StartTimeType
    if (this.hasStartTime()) {
      if (this._timereport.StartTimeType <= 0 && this.starttimetypes.length > 0) {
        this.alertService.Add({ message: this.languageService.getItem(1122), type: 'danger' });
        return false;
      }
      else if (this._timereport.StartTimeType > 0) {
        let startTimeTypeObj = this.listService.find(this.starttimetypes, 'Id', this._timereport.StartTimeType);
        if (!startTimeTypeObj) {
          this.alertService.Add({ message: this.languageService.getItem(1122), type: 'danger' });
          return false;
        }
      }
    }

    //TimeType
    if (this.timetypes.length > 0) {
      if (this._timereport.TimeType <= 0) {
        this.alertService.Add({ message: this.languageService.getItem(1124), type: 'danger' });
        return false;
      }
      else if (this._timereport.TimeType > 0) {
        let timeTypeObj = this.listService.find(this.timetypes, 'Id', this._timereport.TimeType);
        if (!timeTypeObj) {
          this.alertService.Add({ message: this.languageService.getItem(1124), type: 'danger' });
          return false;
        }
      }
    }

    //EndTimeType
    if (this.hasEndTime()) {
      if (this._timereport.EndTimeType <= 0 && this.endtimetypes.length > 0) {
        this.alertService.Add({ message: this.languageService.getItem(1123), type: 'danger' });
        return false;
      }
      else if (this._timereport.EndTimeType > 0) {
        let endTimeTypeObj = this.listService.find(this.endtimetypes, 'Id', this._timereport.EndTimeType);
        if (!endTimeTypeObj) {
          this.alertService.Add({ message: this.languageService.getItem(1123), type: 'danger' });
          return false;
        }
      }
    }

    return true;
  }
  private adjustbreakstart(start: Date, end: Date, breakstart: Date) {

    let newBreakStart: Date = breakstart;

    if ((newBreakStart < start || end < newBreakStart)) {
      //Adjust to start date
      newBreakStart = new Date(start.getFullYear(), start.getMonth(), start.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
      if ((newBreakStart < start || end < newBreakStart)) {
        //Adjust to end date
        newBreakStart = new Date(end.getFullYear(), end.getMonth(), end.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
        if ((newBreakStart < start || end < newBreakStart)) {
          //Can't adjust
          newBreakStart = breakstart;
        }
      }
    }

    return this.dateTimeService.formatWithTimeZone(newBreakStart);
  }
  private init() {

    this.generalService.timetypes.forEach((timetype) => {

      if (timetype.Extra < 0) {
        this._minustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else if (timetype.Extra == 0) {
        this._neutraltimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else {
        this._plustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }

    });
  }

}
