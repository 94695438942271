import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';
import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { TokenService } from '../_services/token.service';
import { DateTimeService } from '../_services/datetime.service';

declare var google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  private _showverification: boolean = false;
  private _showsso: boolean = false;
  private _username: string = '';
  private _password: string = '';
  private _code: string = '';
  private _otp: string = '';
  private _samlfocus: number = 0;
  private _samlcontent: any[] = [];
  private _newscontent: any[] = [];
  private _focus: boolean = true;
  private _loading: boolean = false;
  private _logout: boolean = false;
  private _googleIsInit: boolean = false;

  @ViewChild('btnLogin') btnLogin: ElementRef;
  @ViewChild('btnGoogleLogin') btnGoogle: ElementRef;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private authenticationService: AuthenticationService,
    private dataService: DataService,
    private tokenService: TokenService,
    private dateTimeService: DateTimeService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) {


    this.route.queryParams.subscribe((params) => {
      //Parameters
      this._username = params['u'];
      this._password = params['p'];
      this._code = params['c'];
      this._otp = params['otp'];

      //Saml
      let saml = params['saml'];
      if (saml) {
        this._samlfocus = saml;
      }

      //One-Time-Password
      this._showsso = this._otp ? true : false;
      if (this._code && this._otp) {
        this.authenticationService.otp(this._otp);
        this.authenticationService.verify(this._code)
          .subscribe((res) => {
            this.manageVerification();
          });
      }

      //Login
      let login = params['login'];
      if (login) {
        this.login();
      }

      //Logout
      this._logout = params['logout'] ? true : false;
      if (this._logout) {
        this.dataService.IsLogout = true;
        this.authenticationService.logout();
        this.tokenService.clearTokens();
      }
    });

   

  }


  ngOnInit() {

    if (this._logout) {
      setTimeout(() => {
        this.loadNews();
        this.manageVerification();
        this.loadsso();

        this.dataService.IsLogout = false;
      }, 2000);
    }
    else {
      this.loadNews();
      this.loadsso();
    }
    
  }


  //Properties
  public get showverification() {
    return this._showverification;
  }
  public get showsso() {
    return this._showsso;
  }
  public get username() {
    return this._username;
  }
  public set username(val) {
    this._username = val;
  }
  public get password() {
    return this._password;
  }
  public set password(val) {
    this._password = val;
  }
  public get code() {
    return this._code;
  }
  public set code(val) {
    this._code = val;
  }
  public get samlfocus() {
    return this._samlfocus;
  }
  public get samlcontent() {
    return this._samlcontent;
  }
  public get newscontent() {
    return this._newscontent;
  }
  public get focus() {
    return this._focus;
  }
  public set focus(val) {
    this._focus = val;
  }
  public get loading() {
    return this._loading;
  }


  //Wash
  public wash(s: string) {
    if (s && s.length > 0) {
      return s.replace(/\n/g, '<br>');
    }
    return s;
  }


  //Common Login
  public login() {
    this._loading = true;

    this.authenticationService.login(this._username, this._password)
      .subscribe((res) => {
        this.manageVerification();
        this._loading = false;
      }, (error) => {
        this._loading = false;
      });
  }


  //Verify header
  public getVerifyHeader() {
    if (this.permissionService.unauthpermissions.LoginVerifyByEmail) {
      //Email
      return this.languageService.getItem(1026);
    }
    else {
      //Mobile
      return this.languageService.getItem(707);
    }
  }


  //SSO login
  public loginsso(id) {
    this.authenticationService.loginsso(id);
  }
  private loadsso() {
    this._samlcontent = [];

    this.dataService.basicRequest('/api/v1/security/sso/providers', 'GET', {})
      .subscribe((res) => {

        this._samlcontent = res;
      });
    
  }



  //Google login
  public logingoogle() {

    if (this.permissionService.unauthpermissions.GoogleSignIn) {

      //To remove earlier cancellations
      document.cookie = "g_state=;path=;expires=Thu, 01 Jan 1970 00:00:01 GMT";


      if (!this._googleIsInit) {
        this._googleIsInit = true;
        
        //Init Google
        google.accounts.id.initialize({
          client_id: this.permissionService.unauthpermissions.GoogleClient,
          auto_select: true,
          cancel_on_tap_outside: false,
          ux_mode: 'popup',
          context: 'signin',
          itp_support: true,
          use_fedcm_for_prompt: true,
          callback: (response) => {

            //Login
            let id_token = response.credential;

            this.authenticationService.tokensignin(id_token, 'Google')
              .subscribe((res) => {
                this.manageVerification();

                window.open(this.route.snapshot.queryParams['returnUrl'] || '/', '_self');
              }, (error) => {
                this.ngZone.run(() => {
                  this.setFocus(this.btnGoogle);
                });

              });
          }
        });
      }

      //Prompt Google for Accounts
      google.accounts.id.prompt((notification) => {
        //below methods where removed when FedCM was introduced https://developers.google.com/identity/gsi/web/guides/fedcm-migration
        //if (notification.isNotDisplayed()) {
        //  console.log('Google NotDisplayed: ' + notification.getNotDisplayedReason());
        //}
        //if (notification.isSkippedMoment()) {
        //  console.log('Google SkippedMoment: ' + notification.getSkippedReason());
        //}
      });

    }
  }



  //Verify
  private manageVerification() {
    this._showverification = (this.tokenService.getToken('isotp').length > 0 && this.tokenService.getToken('isotp') == 'true');
  }
  public verify() {
    this.authenticationService.verify(this._code)
      .subscribe((res) => {
        this.manageVerification();
      });

    
  }


  //Back
  public back() {
    this._showverification = false;
  }



  //Registration
  public registration() {
    this.router.navigate(['/registration']);
  }


  //Lost Password
  public lostpassword() {
    this.router.navigate(['/lostpassword']);
  }



  //News
  private loadNews() {
    this.dataService.basicRequest('/api/v1/news/public', 'GET', {})
      .subscribe((res) => {

        this._newscontent = [];
        res.News.forEach((news) => {
          news.Publish = this.dateTimeService.format(news.Publish, null);

          this._newscontent.push(news);
        });
      });
  }


  //Helpers
  private setFocus(focusElement) {
    setTimeout(() => {
      if (focusElement) {
        let element = focusElement.nativeElement;
        element.focus();
        if (element.select) {
          element.select();
        }
      }
    }, 0); //Create a macrotask that will run in the next VM turn
  }
  private success() {

  }
  private failure() {

  }

}
