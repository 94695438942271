import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { ListUtility } from "../_utilities/list.utility";
import { LanguageService } from "../_services/language.service";

@Component({
  selector: 'swe-fixedheader',
  templateUrl: 'fixedheader.component.html',
})
export class FixedHeaderComponent implements AfterViewInit {

  @Input() datePagerOpt: DatePagerOptions;
  @Input() listutility: ListUtility;
  @Input() all: boolean;
  @Input() more: boolean;
  @Input() access: Number;
  @Input() listoptions: TemplateRef<any>;
  @Input() extra: TemplateRef<any>;
  @Input() after: TemplateRef<any>;
  @Input() resetsticky: boolean;
  @Output() searchEvent = new EventEmitter<any>();
  
  private _loading: boolean;

  constructor(public languageService: LanguageService, private _cd: ChangeDetectorRef) {

  }

  ngAfterViewInit() {
    this._cd.detectChanges();
  }

  public get loading() {
    return this._loading;
  }

  public search(getMore: boolean = false, getAll: boolean = false) {
    this.searchEvent.emit({ getMore: getMore, getAll: getAll });
  }
}


export interface DatePagerOptions {
  hideTime: Boolean
  type: String
}
