import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { PermissionService } from '../_services/permission.service';
import { LanguageService } from '../_services/language.service';
import { GeneralService } from '../_services/general.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { DateTimeService } from '../_services/datetime.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { PropertyService } from '../_services/property.service';


@Component({
  selector: 'swe-objecttoobjects',
  templateUrl: './objecttoobjects.component.html'
})
export class ObjectToObjectsComponent implements OnInit {

  @Input() open: boolean = false;
  @Input() id: number = 0;
  @Input() data: any = {};

  private _objects: any[] = [];
  private _top: number = 25;
  private _multiple: number = 1;
  private _more: boolean = false;
  private _hits: number = 0;


  constructor(
    public authenticationService: AuthenticationService,
    public permissionService: PermissionService,
    public languageService: LanguageService,
    public settingService: SettingService,
    public propertyService: PropertyService,
    private generalService: GeneralService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private viewCacheService: ViewCacheService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    

  }



  ngOnInit() {

    if (this.open) {
      this.load();
    }

  }


  //Properties
  public get objects() {
    return this._objects;
  }
  public get hits() {
    return this._hits;
  }
  public get more() {
    return this._more;
  }



  //Methods
  public load(getmore:boolean=false) {

    this._more = false;

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._objects = [];
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      Category: this.data.Key,
      Id: this.id
    }

    this.dataService.tokenRequest('/api/v1/objects/children/search', 'POST', filter)
      .subscribe((res) => {

        res.Objects.forEach((item) => {

          this.manageProfile(item.Profile);
          this._objects.push(item);
        });

        this._hits = this._objects.length;
        this._more = res.More;
      });
  }
  public goto(row, e) {
    e.stopPropagation();

    let url = '/objecttoobjects/' + this.data.Key + '/' + this.id + '/' + row.Id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  

  //Functions
  private manageProfile(profileList: any[]) {

    profileList.forEach((profile) => {

      let property = this.propertyService.getProperty(profile.Property);
      if (property) {
        profile.Type = property.Type;
        if (profile.Type == 'System.DateTime' || profile.Type == 'System.Date' || profile.Type == 'System.Time') {
          if (property.Format && property.Format.length > 0) {
            profile.FormatValue = this.dateTimeService.format(profile.Value, property.Format);
          }
          else {
            profile.FormatValue = this.dateTimeService.format(profile.Value, null);
          }
        }
        else if (profile.Type == 'System.List' || profile.Type == 'System.MultiList') {
          let res = '';

          if (profile.Value != null) {
            profile.Value.toString().split('|').forEach((v) => {

              property.Items.forEach((pi) => {

                if (pi.Id == parseInt(v)) {

                  if (res.length > 0) {
                    res += '| ';
                  }
                  res += pi.Name;
                }

              });

            });
          }

          profile.FormatValue = res;

        }
        else if (profile.Type == 'System.Link') {
          let container: any[] = profile.Value.toString().split('|');
          profile.FormatValue = container[0];
          if (container.length > 1) {
            profile.Url = container[1];
          }
        }
        else {
          profile.FormatValue = profile.Value;
        }
      }

    });

  }
}
