import { Component, OnChanges, Input, ElementRef, ViewChild, OnDestroy, EventEmitter, Output, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ListService } from '../../_services/list.service';
import { PropertyService } from '../../_services/property.service';



@Component({
  selector: 'swe-usermarkeddatedata',
  templateUrl: './usermarkeddatedata.component.html'
})
export class UserMarkedDateDataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() redirect: number = 0;
  @Input() userId: number;
  @Input() open: boolean;
  @Output() usermarkeddatedataChange = new EventEmitter<any>();
  @ViewChild('sweSaveElement') saveElement: ElementRef;

  private _subscriptions: Subscription[] = [];
  private _usermarkeddate: any;
  private _loading: boolean;
  private _hiddenuser: string = '';
  private _repeatdays: number;
  private _repeatend: Date;
  private _createnew: boolean = false;
  private _types: any[] = [];
  private _chosenUsers: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save();
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this._types = [];
    this.generalService.markeddatetypes.forEach((type) => {
      if (type.Access > 1) {
        this._types.push(type);
      }
    });

  }

  ngOnChanges() {
    if (this.open) {
      //this._createnew = (this.id == 0);
      this.load();
    }
    if (this.userId > 0) {
      this.dataService.tokenRequest('/api/v1/users/' + this.userId, 'GET')
        .subscribe(res => {
          if (res) {

            this._hiddenuser = res.Name;
          }
        });
    }
  }

  /*Properties*/
  public get chosenUsers() {
      return this._chosenUsers;
  }
    public set chosenUsers(val) {
        this._chosenUsers = val;
  }
  public get loading() {
    return this._loading;
  }
  public get usermarkeddate() {
    return this._usermarkeddate;
  }
  public get hiddenuser() {
    return this._hiddenuser;
  }
  public get serieDayList() {
    return [
      { Id: 2, Name: this.languageService.getItem(209) },
      { Id: 4, Name: this.languageService.getItem(210) },
      { Id: 8, Name: this.languageService.getItem(211) },
      { Id: 16, Name: this.languageService.getItem(212) },
      { Id: 32, Name: this.languageService.getItem(213) },
      { Id: 64, Name: this.languageService.getItem(214) },
      { Id: 1, Name: this.languageService.getItem(215) }
    ];
  }
  public get repeatdays() {
    return this._repeatdays;
  }
  public set repeatdays(val) {
    this._repeatdays = val;
  }
  public get repeatend() {
    return this._repeatend;
  }
  public set repeatend(val) {
    this._repeatend = val;
  }
  public get createnew() {
    return this._createnew;
  }
  public set createnew(val) {
    this._createnew = val;
  }
  public get types() {
    return this._types;
  }



  /*Methods*/
  public userChosen(e) {
     let index = this._chosenUsers.map(function (user) { return user.Id; }).indexOf(e.item.Id);

     if (index == -1) {
       this._chosenUsers.push(e.item);
     }
  }
  public removeuser(item, e) {
    let index = this._chosenUsers.indexOf(item);
    if (index > -1) {
      this._chosenUsers.splice(index, 1);
    }

    e.stopPropagation();
  }

  public profiledone() {
    if (this._usermarkeddate.Access > 1) {
      let element = this.saveElement.nativeElement;
      element.click();
    }
  }
  public load() {

    if (!this.open) { return; }

    this._loading = true;

    this._repeatdays = 0;
    this._repeatend = this.dateTimeService.lastDayInWeek(new Date());

    this.dataService.tokenRequest('/api/v1/usermarkeddates/' + this.id, 'GET')
      .subscribe(res => {
        if (res) {
          this._usermarkeddate = res;

          let typeObj = this.listService.find(this._types, 'Id', res.Type);
          if (!typeObj) {
            this._types.push({ Id: res.Type, Name: res.TypeName });
          }

          this.usermarkeddatedataChange.emit({
            Id: this._usermarkeddate.Id,
            UserId: this._usermarkeddate.UserId
          });

          if (this.id == 0) {
            this.changeColor();
          }

          this._loading = false;
        }
      });
  }
  public save() {
    if (this._usermarkeddate.Access < 2) { return; }
    if (!this.dateTimeService.validation(this._usermarkeddate.Start, this._usermarkeddate.End, true)) { return; }
    if (!this.propertyService.validate(this._usermarkeddate.Profile)) { return; }
    if (!this.validateuser()) { return; }

    let verb = 'POST';
    let path = '/api/v1/usermarkeddates/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;
    }
    const lastUser = this._chosenUsers.pop();
    const userId = this._usermarkeddate.UserId > 0 ? this._usermarkeddate.UserId : lastUser?.Id ?? 0;
    let dto: any = {
      Start: this._usermarkeddate.Start,
      End: this._usermarkeddate.End,
      UserId: this.userId > 0 ? this.userId : userId,
      Type: this._usermarkeddate.Type,
      Profile: this._usermarkeddate.Profile,
      RepeatEnd: this._repeatend,
      RepeatDays: this._repeatdays,
      UserIds: this._chosenUsers.map(x => { return x.Id })
    };

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });
          if (this._createnew) {
            if (this.redirect > 0) {
              this.router.navigate(['/usermarkeddates/0/redirect/' + this.redirect]);
              if (lastUser) {
                this._chosenUsers.push(lastUser);
              }
              this.load();
              return;
            }
            this.router.navigate(['/usermarkeddates/0/user/', this.userId]);
            this.load();
          }
          else if (response.status == 201) {
            if (this._chosenUsers.length > 0) {
              this.updateRedirectDates();
              const url = this.redirect == 2 ? '/timeline' : '/usermarkeddates';
              this.router.navigate([url]);
              return;
            }
            let url = response.headers.get('Location');
            if (this.userId > 0) {
              url += '/user/' + this.userId;
            }
            this.router.navigate([url], { replaceUrl: true });
            //Will be automatically reloaded
          }
          else {
            this.load();
          }
        }
      });
  }

  public delete() {

    this.dataService.tokenRequest('/api/v1/usermarkeddates/' + this.id + '/users/' + this._usermarkeddate.UserId, 'DELETE', {}, 'text')
      .subscribe(res => {
        if (res) {

          this.alertService.Add({ message: res, type: 'success' });

          if (this.id == 0) {
            //Might be merged
            this.router.navigateByUrl('/usermarkeddates/0');
          }
          else {
            this.location.back();
          }
        }
      });
  }
  public changeType() {
    this.changeColor();
  }


  //Functions
  private changeColor() {
    let item = this.listService.find(this.generalService.markeddatetypes, 'Id', parseInt(this._usermarkeddate.Type));
    if (item) {
      this._usermarkeddate.Color = item.Color;
    }
  }
  private validateuser() {
    if (this.permissionService.permissions.UserMarkedDateSearch > 0 && this.userId == 0 && this._chosenUsers.length == 0 && this._usermarkeddate.UserId == 0) {
      this.alertService.Add({ message: this.languageService.getItem(735), type: 'danger' });
      return false;
    }

    return true;
  }

  private updateRedirectDates() {
    if (this.redirect  == 2) {
      return;
    }
    const start = new Date(this._usermarkeddate.Start);
    this.settingService.user.availabilitystart = start;
    this.settingService.user.availabilityend = start;
  }
}
