import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { ListService } from '../../_services/list.service';
import { AlertService } from '../../_services/alert.service';
import { GeneralService } from '../../_services/general.service';


@Component({
  selector: 'app-timeruledetail',
  templateUrl: './timeruledetail.component.html'
})
export class TimeRuleDetailComponent implements OnInit {

  private _id: number = 0;
  private _contractid: number = 0;
  private _timerule: any = {};
  private _weekdays: any[] = [];
  private _types: any[] = [];
  private _timeunits: any[] = [];
  private _timegroups: any[] = [];
  private _timeperiods: any[] = [];
  private _dayfilters: any[] = [];
  private _levels: any[] = [];
  private _contracts: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private generalService: GeneralService,
    private listService: ListService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    //Reset
    settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this._contractid = Number(params.get('contractId'));

      this.load();
    });
  }


  ngOnInit() {

    this.init();

  }


  //Properties
  public get id() {
    return this._id;
  }
  public get contractid() {
    return this._contractid;
  }
  public get timerule() {
    return this._timerule;
  }
  public get weekdays() {
    return this._weekdays;
  }
  public get types() {
    return this._types;
  }
  public get timeunits() {
    return this._timeunits;
  }
  public get timegroups() {
    return this._timegroups;
  }
  public get timeperiods() {
    return this._timeperiods;
  }
  public get dayfilters() {
    return this._dayfilters;
  }
  public get levels() {
    return this._levels;
  }
  public set levels(val) {
    this._levels = val;
  }
  public get contracts() {
    return this._contracts;
  }
  


  //Methods
  public load() {
    this.dataService.tokenRequest('/api/v1/system/timerules/' + this._id, 'GET', {})
      .subscribe((res) => {

        if (this._id <= 0) {
          res.ContractId = this._contractid;
        }

        this._timerule = res;
        this._levels = [...res.Levels];
      });
  }
  public getTimeUnit(index: number) {
    if (index && index < this._timeunits.length) {
      return this._timeunits[index].Name;
    }
    return '';
  }
  public getDayFilter(dayfilter) {
    let res = '';

    let day = 0;
    for (let i = 1; i < 128; i *= 2) {

      if ((dayfilter & i) == i) {
        if (res.length > 0) {
          res += ", ";
        }

        res += this._dayfilters[day].Name;
      }

      day++;
    }

    return res;
  }
  public addLevel(e) {
    let item = e.item;
    let index = this._levels.map(function (level) { return level.Id; }).indexOf(item.Id);

    if (index == -1) {
      item.active = true;
      this._levels.push(item);
      this.listService.sort(this._levels, ['Path', 'Name'], true);
    }
  }
  public removeLevel(item) {
    let index = this._levels.indexOf(item);
    if (index > -1) {
      this._levels.splice(index, 1);
    }
  }
  public copy() {
    this._id = 0;
    this.save();
  }
  public save() {

    let verb = 'POST';
    let path = '/api/v1/system/timerules/'
    if (this._id > 0) {
      verb = 'PUT';
      path += this._id;
    }

    if (!this._timerule.ContractId) {
      this._timerule.ContractId = 0;
    }

    if (!this._timerule.TimePeriodNo) {
      this._timerule.TimePeriodNo = 0;
    }

    if (!this._timerule.TimePeriod) {
      this._timerule.TimePeriod = -1;
    }

    this._timerule.Levels.forEach((item) => {

      let id = this._levels.map(function (level) { return level.Id; }).indexOf(item.Id);
      if (id == -1) {
        item.IsDeleted = true;
        this._levels.push(item);
      }
    });
    this._timerule.Levels = this._levels;

    this.dataService.tokenRequest(path, verb, this._timerule, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location').replace('system/timerules', 'systemadmin/timerules')], { replaceUrl: true });
        }

        this.load();
      });
  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/system/timerules/' + this._id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });
        this.location.back();
      });
  }




  //Functions
  private init() {

    this.generalService.contracts.forEach((contract) => {

      this._contracts.push({ Id: contract.Key, Name: contract.Value });

    });

    this._weekdays = [
      { Id: 0, Name: this.languageService.getItem(209) },
      { Id: 1, Name: this.languageService.getItem(210) },
      { Id: 2, Name: this.languageService.getItem(211) },
      { Id: 3, Name: this.languageService.getItem(212) },
      { Id: 4, Name: this.languageService.getItem(213) },
      { Id: 5, Name: this.languageService.getItem(214) },
      { Id: 6, Name: this.languageService.getItem(215) }
    ];

    this._types = [
      { Id: 0, Name: this.languageService.getItem(599) },
      { Id: 1, Name: this.languageService.getItem(600) }
    ];

    this._timeunits = [
      { Id: 0, Name: this.languageService.getItem(622) },
      { Id: 1, Name: this.languageService.getItem(623) },
      { Id: 2, Name: this.languageService.getItem(624) },
      { Id: 3, Name: this.languageService.getItem(625) },
      { Id: 4, Name: this.languageService.getItem(626) },
      { Id: 5, Name: this.languageService.getItem(627) },
      { Id: 6, Name: this.languageService.getItem(628) },
      { Id: 7, Name: this.languageService.getItem(629) },
      { Id: 8, Name: this.languageService.getItem(630) }
    ];

    this._timegroups = [
      { Id: 0, Name: this.languageService.getItem(622) },
      { Id: 1, Name: this.languageService.getItem(623) },
      { Id: 2, Name: this.languageService.getItem(624) },
      { Id: 3, Name: this.languageService.getItem(625) },
      { Id: 4, Name: this.languageService.getItem(626) },
      { Id: 5, Name: this.languageService.getItem(627) },
      { Id: 6, Name: this.languageService.getItem(628) },
      { Id: 7, Name: this.languageService.getItem(629) },
      { Id: 8, Name: this.languageService.getItem(630) }
    ];

    this._timeperiods = [
      { Id: 0, Name: this.languageService.getItem(622) },
      { Id: 1, Name: this.languageService.getItem(623) },
      { Id: 2, Name: this.languageService.getItem(624) },
      { Id: 3, Name: this.languageService.getItem(625) },
      { Id: 4, Name: this.languageService.getItem(626) },
      { Id: 5, Name: this.languageService.getItem(627) },
      { Id: 6, Name: this.languageService.getItem(628) },
      { Id: 7, Name: this.languageService.getItem(629) },
      { Id: 8, Name: this.languageService.getItem(630) }
    ];

    this._dayfilters = [
      { Id: 1, Name: this.languageService.getItem(209) },
      { Id: 2, Name: this.languageService.getItem(210) },
      { Id: 4, Name: this.languageService.getItem(211) },
      { Id: 8, Name: this.languageService.getItem(212) },
      { Id: 16, Name: this.languageService.getItem(213) },
      { Id: 32, Name: this.languageService.getItem(214) },
      { Id: 64, Name: this.languageService.getItem(215) }
    ];
  }

}
