<div class="d-inline" (click)="change()" *ngIf="!readonly">
  <div class="d-inline-block align-top me-1" *ngIf="permissionService.permissions.BreakStart||alwaysShowStart">
    <swe-element [type]="type" [label]="languageService.getItem(786)" [(model)]="modelStart" [access]="3" (modelChange)="manageBreakStart($event)" [disabled]="disabledStart||mealbreak" [container]="{hideSeconds: true, hideReset: true, markChanges: markChanges}" [changed]="startChanged"></swe-element>
  </div>
  <div class="d-inline-block align-top swe-w-20">
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(786)" [(model)]="model" [access]="3" (modelChange)="manageBreak($event)" [disabled]="disabled||mealbreak" [changed]="changed" [container]="{markChanges: markChanges}"></swe-element>
  </div>
  <div class="d-inline-block ms-1">
    <button class="btn btn-swe" style="color:black;cursor:unset;" [disabled]="true"><span class="d-none d-xl-inline">{{languageService.getItem(786)}}</span><span class="d-xl-none">{{languageService.getItem(786).substring(0,3)}}</span></button>
    <button class="btn btn-swe" *ngIf="reset&&!disabled" [attr.title]="languageService.getItem(915)" (click)="resetBreak()"><i class="bi bi-trash-fill swe-click" [ngClass]="{'swe-grayed-out': (model == -1)}"></i></button>
    <span class="align-middle small ms-3" *ngIf="hasBreakInfo">({{languageService.getItem(930)}})</span>
  </div>
  <ng-container *ngIf="mealbreakAccess > 0">

    <div class="d-inline-block ms-3">
      <i class="bi bi-swe-fw bi-swe bi-stopwatch-fill" [title]="languageService.getItem(1415)" [attr.aria-label]="languageService.getItem(1415)"></i>
    </div>
    <div class="d-inline-block">
      <swe-element type="System.Boolean" [disabled]="mealbreakAccess<2||disabled||readonly" (modelChange)="manageMealbreak()" [model]="mealbreak" [label]="languageService.getItem(1415)" [(setFocus)]="mealbreakfocus"></swe-element>
    </div>
  </ng-container>
</div>
<div class="d-inline" *ngIf="readonly">
  <span style="vertical-align:sub;">{{readonlystring}}</span>
</div>
